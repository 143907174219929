import React from 'react';
import {v4 as uuidv4} from 'uuid';

// Interfaces
import {IUser} from '../../../models/IUser';
import {Column} from '../TableWrapper';

// Styles
import './style.scss'

// Images
import arrowDown from './../../../assets/arrow-down.png';
import arrowUp from './../../../assets/arrow-up.png';

interface TableProps {
    data: any,
    columns: Column[],
    handleSort: (value: string) => void,
    sort: string
}

const Table = ({data, columns, sort, handleSort}: TableProps) => {
    const getCellValue = (column: Column, item: IUser) => {
        if (column.getItemValue) {
            return (
                <th className={`px-3 fw-normal ${column.style}`} key={uuidv4()}>
                    {column.getItemValue(item).text}
                </th>
            )
        } else if (column.render) {
            return (
                <th className={`px-3 fw-normal ${column.style}`} key={uuidv4()}>
                    {column.render(item)}
                </th>
            )
        }
    };

    const sortTable = (key: string) => {
        if (sort.includes(key)) {
            if (sort[0] === '-') {
                handleSort("");
            } else {
                handleSort(`-${key}`);
            }
        } else {
            handleSort(key);
        }
    }

    const getSortArrow = (key?: string) => {
        if (key && sort.includes(key)) {
            if (sort[0] === '-') {
                return <img src={arrowDown} alt="desc" className="w-12 ms-2"/>
            } else {
                return <img src={arrowUp} alt="asc" className="w-12 ms-2"/>
            }
        }

        return null;
    };

    return (
        <table className="w-100 mt-4">
            <thead>
            <tr className="h-40 head-row border-1 border-bottom border-light-gray">
                {columns.map(item => (
                    <th
                        className={`text-uppercase fw-bolder px-3 ${item.key ? 'cursor-pointer' : ''} headCell ${item.style}`}
                        key={uuidv4()}
                        onClick={()=> item.key && sortTable(item.key)}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <span>{item.title}</span>
                            {getSortArrow(item.key)}
                        </div>
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data.map((item: any) => (
                <tr className="h-40 border-1 border-bottom border-light-gray hover-pale-blue" key={uuidv4()}>
                    {columns.map(column => getCellValue(column, item))}
                </tr>
            ))}
            </tbody>
        </table>
    )
};

export default Table;