import {combineReducers, configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk'
import {authReducer} from './auth/reducer';
import {cohortReducer} from './cohorts/reducer';
import {districtReducer} from './districts/reducer';
import {schoolReducer} from './schools/reducer';
import {roleReducer} from './roles/reducer';
import {userReducer} from './users/reducer';
import {appReducer} from './apps/reducer';
import {errorReducer} from './errors/reducer';
import {studentReducer} from './students/reducer';


const rootReducer = combineReducers({
    auth: authReducer,
    cohorts: cohortReducer,
    districts: districtReducer,
    schools: schoolReducer,
    roles: roleReducer,
    users: userReducer,
    apps: appReducer,
    errors: errorReducer,
    students: studentReducer
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(thunk)
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']