import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {isEmpty} from 'lodash';

// Reducers
import {selectDistrictState} from '../../store/districts/reducer';
import {selectCohortState} from '../../store/cohorts/reducer';
import {selectSchoolState} from '../../store/schools/reducer';
import {selectRoleState} from '../../store/roles/reducer';
import {selectUserState} from '../../store/users/reducer';
import {selectAuthState} from '../../store/auth/reducer';
import {selectAppState} from '../../store/apps/reducer';

// Actions
import {getDistricts} from '../../store/districts/actions';
import {getConnectedApps} from '../../store/apps/actions';
import {getCohorts} from '../../store/cohorts/actions';
import {getSchools} from '../../store/schools/actions';
import {getRoles} from '../../store/roles/actions';

// Configs
import {getUserEditConfig} from '../../config/confings';

// Components
import ModelEditComponent from '../EditComponent';


// Interfaces
import {StateInterface} from '../Filters';

// Models
import {ISchool} from '../../models/ISchool';

// Helpers
import {isAdmin} from '../../helpers';

interface UserDetailsInterface {
    title: string,
    initialData: {},
    backPage: string,
    onSubmit: (data: object) => void
}

const UserEditBasicPage = ({title, initialData, backPage, onSubmit}: UserDetailsInterface) => {
    const dispatch = useAppDispatch();
    const {selectedUser} = useAppSelector(selectUserState);
    const {authUser} = useAppSelector(selectAuthState);
    const {districts} = useAppSelector(selectDistrictState);
    const {cohorts} = useAppSelector(selectCohortState);
    const {schools} = useAppSelector(selectSchoolState);
    const {roles} = useAppSelector(selectRoleState);
    const {apps} = useAppSelector(selectAppState);
    const [availableSchools, setAvailableSchools] = useState<ISchool[]>(schools);
    const [isSelectedDistrict, setIsSelectedDistrict] = useState<boolean>(false)

    useEffect(()=>{
        if (isEmpty(districts)) {
            dispatch(getDistricts());
        }
        if (isEmpty(schools)) {
            dispatch(getSchools());
        }
        if (isEmpty(roles)) {
            dispatch(getRoles());
        }
        if (isEmpty(apps)) {
            dispatch(getConnectedApps());
        }
        if (isEmpty(cohorts)) {
            dispatch(getCohorts());
        }
    },[]);

    useEffect(() => {
        if (!isEmpty(schools)) {
            setAvailableSchools(schools);
        }
    },[schools])

    const onSave = async (data: any) => {
        const apps: StateInterface = [];
        if (!isEmpty(data.apps)) {
            Object.entries(data.apps).map(([key, value]) => {
                if (value) {
                    apps.push(+key);
                }
            })
        }

        await onSubmit({...data, apps});
    };

    const onDistrictSelect = (state: StateInterface) => {
        if (state['district']) {
            setIsSelectedDistrict(!!state['district'])
            setAvailableSchools(schools.filter(item => item.district_id == state['district']))
        }
    };

    return (
        <ModelEditComponent
            title={title}
            initialData={initialData}
            backPage={backPage}
            fieldsConfig={getUserEditConfig(roles, availableSchools, districts, cohorts, apps, authUser.id === selectedUser.id, authUser?.role?.name, isSelectedDistrict)}
            onSubmit={onSave}
            onStateChange={onDistrictSelect}
        />
    );
};

export default UserEditBasicPage;
