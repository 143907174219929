import React, {useMemo} from 'react';
import {v4 as uuidv4} from 'uuid';
import './style.scss';
// Components
import {Button} from 'react-bootstrap';

// Images
import arrowLeft from './../../assets/arrow-left.png';
import arrowRight from './../../assets/arrow-right.png';

// Helpers
import {getPages} from '../../helpers';

interface Pagination {
    currentPage: number,
    lastPage: number,
    onChange: (name: string, value: number) => void
}

const PaginationComponent = ({currentPage, lastPage, onChange}: Pagination) => {
    const pages = useMemo(() => getPages(currentPage, lastPage),[currentPage, lastPage]);

    const onPageChange = (page: number) => {
        onChange('page', page);
    };

    if (lastPage < 2) {
        return null;
    }

    return (
        <div id="pagination" className="d-flex justify-content-center">
            <Button
                variant="outline-secondary"
                className={`${currentPage === 1 && 'disabled-button'}`}
                onClick={() => onPageChange(currentPage - 1)}
            >
                Prev
            </Button>
            {pages.map(page => (
                <Button
                    key={uuidv4()}
                    variant="outline-secondary"
                    className={`${typeof page === "number" && 'cursor-pointer'} ${page === currentPage && 'active-button'}`}
                    onClick={() => typeof page === "number" && onPageChange(page)}
                >
                    {page}
                </Button>
            ))}
            <Button
                variant="outline-secondary"
                className={`${currentPage === lastPage && 'disabled-button'}`}
                onClick={() => onPageChange(currentPage + 1)}
            >
                Next
            </Button>
        </div>
    )
};

export default PaginationComponent;