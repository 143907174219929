import React from 'react';
import {v4 as uuidv4} from 'uuid';

// Components
import {Container, Row, Col, Card, Button} from 'react-bootstrap';

export interface DetailsConfigInterface {
    title: string,
    leftColumn: {label: string, value: any}[],
    rightColumn: {label: string, value: any}[]
}

interface DetailsComponentProps {
    onEdit: () => void,
    detailsConfig: DetailsConfigInterface
}
const DetailsComponent = ({onEdit, detailsConfig}: DetailsComponentProps) => {

    return (
        <Container className="mb-4">
            <Row className="justify-content-center">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h2 className="fw-bold">{detailsConfig.title}</h2>
                                <Button
                                    variant="outline-secondary"
                                    className="rounded h-40"
                                    onClick={onEdit}
                                >
                                    Edit
                                </Button>
                            </div>
                            <hr/>
                            <div className="d-flex">
                                <div className="w-50">
                                    {detailsConfig.leftColumn.map(item => (
                                        <div className="d-flex" key={uuidv4()}><div className="w-medium fw-bold">{item.label}:</div> {item.value ?? "-"}</div>
                                    ))}
                                </div>
                                <div className="w-50">
                                    {detailsConfig.rightColumn.map(item => (
                                        <div className="d-flex" key={uuidv4()}><div className="w-medium fw-bold">{item.label}:</div> {item.value ?? "-"}</div>
                                    ))}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

export default DetailsComponent;