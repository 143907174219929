import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useNavigate} from 'react-router-dom';

// Components
import ListTableHeaderComponent from '../../components/ListTableHeader';
import ListTableWrapper from '../../components/ListTable/TableWrapper';
import FiltersComponent from '../../components/Filters';
import {TailSpin} from 'react-loader-spinner';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';

// Images
import plusIcon from '../../assets/plus-icon.png';

// Configs
import {
    getConnectedAppsFilterConfig,
    getConnectedAppsListColumns
} from '../../config/confings';

// Reducers
import {selectAppState} from '../../store/apps/reducer';

// Actions
import {
    deleteConnectedApp,
    getConnectedApps,
    setConnectedAppsFilters,
    setConnectedAppsPagination,
    setConnectedAppsSort
} from '../../store/apps/actions';

const ConnectedAppsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const {apps, filters, pagination, sort} = useAppSelector(selectAppState);

    useEffect(() => {
        (async () => {
                await getList();
                setIsLoading(false);
            }
        )();
    }, [])

    const setFilters = async (data: object) => {
        dispatch(setConnectedAppsFilters(data));
        await getList();
    };

    const getList = async () => {
        await dispatch(getConnectedApps());
    };

    const handlePagination = async (name: string, value: number | string) => {
        dispatch(setConnectedAppsPagination(name, +value));
        if (name === "perPage") {
            dispatch(setConnectedAppsPagination("page", 1));
        }
        await getList();
    };

    const handleSort = async (value: string) => {
        dispatch(setConnectedAppsSort(value));
        await getList();
    };

    const createApp = () => {
        navigate("/connected-apps/create-app", {replace: true});
    };

    const deleteItem = async (id: number) => {
        await dispatch(deleteConnectedApp(id));
    };

    const viewItem = (id: number) => {
        navigate(`/connected-apps/${id}`, {replace: true});
    };

    const editItem = (id: number) => {
        navigate(`/connected-apps/${id}/edit`, {replace: true});
    };

    const getTableHeader = () => {
        return (
            <ListTableHeaderComponent
                filters={filters}
                handlePagination={handlePagination}
                itemsName="connected apps"
                pagination={pagination}
                setFilters={setFilters}
            />
        )
    };

    return (
        <Container className="mb-4">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-middle">
                                <h1 className="m-0">Connected Apps</h1>
                                <Button variant="primary" className="rounded mt-1 d-flex align-items-center" onClick={() => createApp()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>                        
                                    Create connected app
                                </Button>
                            </div>
                            <hr/>
                            <FiltersComponent
                                filterConfig={getConnectedAppsFilterConfig()}
                                initialFilters={filters}
                                setFilters={setFilters}
                            />
                            <hr/>
                            {isLoading
                                ? <div className="d-flex justify-content-center align-items-center my-5">
                                    <TailSpin
                                        height="400"
                                        width="80"
                                        color="#53c154"
                                        visible={true}
                                    />
                                </div>
                                : <ListTableWrapper
                                    pagination={pagination}
                                    handlePagination={handlePagination}
                                    header={getTableHeader()}
                                    handleSort={handleSort}
                                    sort={sort}
                                    listData={apps}
                                    columns={getConnectedAppsListColumns(deleteItem, viewItem, editItem)}
                                />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

export default ConnectedAppsPage;
