import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAlert} from 'react-alert';
import {useAppDispatch} from '../../hooks/redux';

//Components
import ModelEditComponent from '../../components/EditComponent';

// Configs
import {getConnectedAppEditConfig} from '../../config/confings';

// Actions
import {createConnectedApp} from '../../store/apps/actions';

const ConnectedAppCreatePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const alert = useAlert();

    const backPage = "/connected-apps";

    const onSave = async (data: any) => {
        try {
            await dispatch(createConnectedApp(data));
            navigate(backPage, {replace: true});
            alert.success("Connected App created successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <ModelEditComponent
            title="Create Connected App"
            initialData={{}}
            backPage={backPage}
            fieldsConfig={getConnectedAppEditConfig()}
            onSubmit={onSave}
        />
    )
};

export default ConnectedAppCreatePage;