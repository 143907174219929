import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useStateMap} from 'react-us-states';
import {useAlert} from 'react-alert';

// Components
import ModelEditComponent from '../../components/EditComponent';
import {TailSpin} from 'react-loader-spinner';

// Configs
import {getSchoolEditConfig} from '../../config/confings';

// Constants
import {getStates} from '../../constants';

// Reducers
import {selectDistrictState} from '../../store/districts/reducer';
import {selectSchoolState} from '../../store/schools/reducer';

// Actions
import {getSchool, updateSchool} from '../../store/schools/actions';

// Helpers
import {getBackPage} from '../../helpers';

const SchoolUpdatePage = () => {
    const dispatch = useAppDispatch();
    const statesMap = useStateMap();
    const [searchParams] = useSearchParams();
    const alert = useAlert();
    const {schoolId} = useParams();
    const navigate = useNavigate();
    const {districts} = useAppSelector(selectDistrictState);
    const {selectedSchool} = useAppSelector(selectSchoolState);
    const backPage = "/schools";

    useEffect(() => {
        if (schoolId && selectedSchool.id !== schoolId) {
            dispatch(getSchool(schoolId));
        }
    }, [])

    const onSave = async (data: any) => {
        try {
            const {beacon, drco, ...rest} = data
            const changedData = {
                ...rest,
                app_access: {
                    beacon,
                    drco
                }
            }

            await dispatch(updateSchool(selectedSchool.id, changedData));
            navigate(getBackPage(backPage, searchParams),{replace: true})
            alert.success("School updated successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <div>
            {selectedSchool.id != schoolId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                : <ModelEditComponent
                    title="Update School"
                    initialData={{...selectedSchool, district: selectedSchool.district_id, beacon: selectedSchool.app_access.beacon, drco: selectedSchool.app_access.drco}}
                    backPage={backPage}
                    fieldsConfig={getSchoolEditConfig(getStates(statesMap), districts)}
                    onSubmit={onSave}
                />
            }
        </div>
    )
};

export default SchoolUpdatePage;
