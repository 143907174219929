import React, {ChangeEvent} from 'react';

interface CustomTextareaProps {
    size?: "small" | "medium" | "large" | "huge",
    placeholder: string,
    onChange: (name: string, value: number | string) => void,
    name: string,
    value?: string | number,
    className?: string,
    label?: string,
    error?: string
}

const CustomTextarea = ({size = "medium", placeholder, onChange, name, value, className, label, error}:CustomTextareaProps) => {
    const handleChange = (event: { target: { value: string | number; }; }) => {
        onChange(name, event.target.value);
    };

    return (
        <div className={`d-flex flex-column ${className}`}>
            {label &&
                <label className="mb-1 fw-bold">
                    {label}
                </label>
            }
            <textarea
                name={name}
                defaultValue={value}
                className={`rounded-3 border p-2 w-${size}`}
                placeholder={placeholder}
                onBlur={handleChange}
            />
            {error &&
                <p className="error">{error}</p>
            }
        </div>
    )
};

export default CustomTextarea;