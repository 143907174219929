import {ICohort} from '../../models/ICohort';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../index';
import {PaginationInterface} from '../users/reducer';

interface CohortsFilters {
    id?: number,
    name?: string
}

interface CohortState {
    selectedCohort: ICohort | any,
    cohorts: ICohort[],
    filters: CohortsFilters,
    sort: string,
    pagination: PaginationInterface
}

const initialState: CohortState = {
    cohorts: [],
    filters: {},
    sort: "",
    pagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    selectedCohort: {}
};

export const cohortSlice = createSlice({
    name: 'cohorts',
    initialState,
    reducers: {
        getCohortsListSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                cohorts: action.payload.data,
                pagination: {
                    page: action.payload?.current_page,
                    perPage: action.payload?.per_page,
                    total: action.payload?.total,
                    lastPage: action.payload?.last_page,
                    from: action.payload?.from,
                    to: action.payload?.to
                }
            }
        },
        getCohortSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedCohort: action.payload
            }
        },
        updateCohortSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedCohort: action.payload
            }
        },
        deleteCohortSuccess: (state, action: PayloadAction<string|number>) => {
            const id = action.payload;
            return {
                ...state,
                cohorts: state.cohorts.filter(item => item.id !== id)
            }
        },
        setCohortsPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        },
        setCohortsSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                sort: action.payload
            }
        },
        setCohortsFiltersSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                filters: action.payload
            }
        }
    }
})

export const { getCohortsListSuccess, getCohortSuccess, updateCohortSuccess, deleteCohortSuccess,
    setCohortsPaginationSuccess, setCohortsSortSuccess, setCohortsFiltersSuccess } = cohortSlice.actions;
export const selectCohortState = (state: RootState) => state.cohorts;
export const cohortReducer = cohortSlice.reducer;