import axios, {
    AxiosError, AxiosResponse,
} from 'axios';
import { getLocalStorage, removeLocalStorage } from '../helpers';
import { LocalStorageKeys } from '../store/keys';
import {setError} from '../store/errors/reducer';
import {AppStore} from '../store';

export type AxiosResponseInterface<RespData, ErrorData = Record<string, never>> = AxiosResponse<RespData> | (AxiosError<ErrorData>);

const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
};

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
});

export const interceptor = (store: AppStore) => {
    axiosInstance.interceptors.request.use((config) => {
        const token = getLocalStorage(LocalStorageKeys.TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        store.dispatch(setError({}));
        return config;
    });

    axiosInstance.interceptors.response.use((response: AxiosResponse) => response, (error) => {
        if (error.response.status === 401) {
            removeLocalStorage(LocalStorageKeys.TOKEN);
            if (!window.location.href.includes('/login')) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
}
