import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';

// Components
import DetailsComponent from '../../components/DetailsComponent';
import {TailSpin} from 'react-loader-spinner';

// Configs
import {getConnectedAppDetailsConfig} from '../../config/confings';

// Reducers
import {selectAppState} from '../../store/apps/reducer';

// Actions
import {getConnectedApp} from '../../store/apps/actions';

const ConnectedAppDetailsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {appId} = useParams();
    const {selectedApp} = useAppSelector(selectAppState);

    useEffect(() => {
        if (appId) {
            dispatch(getConnectedApp(appId));
        }
    },[])

    const onEdit = () => {
        navigate(`/connected-apps/${selectedApp.id}/edit?backPage=${location.pathname}`, {replace: true});
    };

    return (
        <div>
            {selectedApp.id != appId
                ? <div className="d-flex justify-content-center align-items-center my-5">
                    <TailSpin
                        height="500"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                : <DetailsComponent
                    onEdit={onEdit}
                    detailsConfig={getConnectedAppDetailsConfig(selectedApp)}
                />
            }
        </div>
    )
};

export default ConnectedAppDetailsPage;