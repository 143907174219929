import React from 'react';

// Components
import PaginationComponent from '../../Pagination';
import Table from '../Table';

// Interfaces
import {PaginationInterface} from '../../../store/users/reducer';

interface Value {
    text: string | number | null | boolean
}
export interface Column {
    title: string | React.ReactNode,
    key?: string,
    getItemValue?: (item: any) => Value,
    render?: (item: any) => React.ReactNode,
    style?: string
}

interface ListTableProps {
    handleSort: (value: string) => void,
    header?: React.ReactNode,
    sort: string,
    columns: Column[],
    listData: any
}
type ConditionalProps =
    | {
        pagination: PaginationInterface,
        handlePagination: (name: string, value: number | string) => void
      }
    | {
        pagination?: never,
        handlePagination?: never
      };

type Props = ListTableProps & ConditionalProps;

const ListTableWrapper = ({pagination, header, listData, columns, sort, handleSort, handlePagination}: Props) => {
    return (
        <div>
            {header && header}
            <Table
                data={listData}
                columns={columns}
                sort={sort}
                handleSort={handleSort}
            />
            {pagination &&
                <PaginationComponent
                    currentPage={pagination.page}
                    lastPage={pagination.lastPage}
                    onChange={handlePagination}
                />
            }
        </div>
    )
}

export default ListTableWrapper;