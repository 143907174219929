export const perPageOptions = [
    {
        id: 10,
        name: "10"
    },
    {
        id: 20,
        name: "20"
    },
    {
        id: 40,
        name: "40"
    },
    {
        id: 60,
        name: "60"
    },
    {
        id: 80,
        name: "80"
    },
    {
        id: 100,
        name: "100"
    }
];

export const getStates = (statesMap: any) => {
    const states: { id: string; name: string; }[] = [];
    Object.keys(statesMap).map((item) => {
        states.push({
            id: item,
            name: item
        });
    });

    return states;
};

export const grades = [
    {
        id: "K",
        name: "K"
    },
    {
        id: "1",
        name: 1
    },
    {
        id: "2",
        name: 2
    },
    {
        id: "3",
        name: 3
    },
    {
        id: "4",
        name: 4
    },
    {
        id: "5",
        name: 5
    },
    {
        id: "6",
        name: 6
    },
    {
        id: "7",
        name: 7
    },
    {
        id: "8",
        name: 8
    },
    {
        id: "9",
        name: 9
    },
    {
        id: "9",
        name: 9
    },
    {
        id: "10",
        name: 10
    },
    {
        id: "11",
        name: 11
    },
    {
        id: "12",
        name: 12
    }
];

export const archived = [
    {
        id: 0,
        name: "False"
    },
    {
        id: 1,
        name: "True"
    },
]

export const UserRoles = {
    SuperAdmin: 'super-admin',
    CIRSAdmin: 'cirs-admin',
    DistrictAdmin: 'district-admin',
    SchoolAdmin: 'school-admin',
    GeneralClassroom: 'teacher',
    SchoolMentalHealth: 'smhp',
    Guardian: 'guardian'
};

export const SystemAdmins = [UserRoles.SuperAdmin, UserRoles.CIRSAdmin];
