import React, {useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';
import './style.scss';

const DEFAULT = 'password';
const OTP = 'otp';

const LoginPage = () => {
    useEffect(() => {
        localStorage.setItem('state', uuidv4());
        window.location.href = process.env.REACT_APP_SSO_SERVER_URL +
            '/oauth/authorize?client_id=' + process.env.REACT_APP_SSO_CLIENT_ID +
            '&app=cirs&redirect_uri=' + process.env.REACT_APP_URL + '/auth-sso-callback&' +
            'response_type=code&state=' + localStorage.getItem('state') + '&prompt=1';
    },[])

    return (
        <div/>
    )
}

export default LoginPage;
