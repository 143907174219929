import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import ReactGA from "react-ga4";

// Styles
import './App.scss';

// Components
import SsoCallbackComponent from './components/SsoCallbackComponent';
import ConnectedAppDetailsPage from './pages/ConnectedAppDetails';
import ConnectedAppCreatePage from './pages/ConnectedAppCreate';
import ConnectedAppUpdatePage from './pages/ConnectedAppUpdate';
import DistrictDetailsPage from './pages/DistrictDetails';
import StudentDetailsPage from './pages/StudentDetails';
import DistrictCreatePage from './pages/DistrictCreate';
import DistrictUpdatePage from './pages/DistrictUpdate';
import SchoolDetailsPage from './pages/SchoolDetails';
import StudentCreatePage from './pages/StudentCreate';
import StudentUpdatePage from './pages/StudentUpdate';
import CohortDetailsPage from './pages/CohortDetails';
import ConnectedAppsPage from './pages/ConnectedApps';
import SchoolUpdatePage from './pages/SchoolUpdate';
import SchoolCreatePage from './pages/SchoolCreate';
import CohortCreatePage from './pages/CohortCreate';
import CohortUpdatePage from './pages/CohortUpdate';
import DistrictsListPage from './pages/Districts';
import UserDetailsPage from './pages/UserDetails';
import SetPasswordPage from './pages/SetPassword';
import PrivateRoute from './routes/PrivateRoute';
import UserCreatePage from './pages/UserCreate';
import UserUpdatePage from './pages/UserUpdate';
import StudentsListPage from './pages/Students';
import SchoolsListPage from './pages/Schools';
import CohortsListPage from './pages/Cohorts';
import UsersListPage from './pages/Users';
import LoginPage from './pages/Login';

export const App: React.FC = () => {
    ReactGA.initialize("G-GRLPKX72NE");
    const authenticationPath: string = "/login";
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element=<LoginPage/> />
                <Route path="/login" element=<LoginPage/> />
                <Route path="/auth-sso-callback" element=<SsoCallbackComponent/> />
                <Route path="/set-password" element=<SetPasswordPage/> />
                <Route path="/users" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<UsersListPage/>}
                    />
                }/>
                <Route path="/users/create-user" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<UserCreatePage/>}
                    />
                }/>
                <Route path="/users/:userId" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<UserDetailsPage/>}
                    />
                }/>
                <Route path="/users/:userId/edit" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<UserUpdatePage/>}
                    />
                }/>
                <Route path="/districts" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<DistrictsListPage/>}
                    />
                }/>
                <Route path="/districts/create-district" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<DistrictCreatePage/>}
                    />
                }/>
                <Route path="/districts/:districtId/edit" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<DistrictUpdatePage/>}
                    />
                }/>
                <Route path="/districts/:districtId" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<DistrictDetailsPage/>}
                    />
                }/>
                <Route path="/schools" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<SchoolsListPage/>}
                    />
                }/>
                <Route path="/schools/create-school" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<SchoolCreatePage/>}
                    />
                }/>
                <Route path="/schools/:schoolId/edit" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<SchoolUpdatePage/>}
                    />
                }/>
                <Route path="/schools/:schoolId" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<SchoolDetailsPage/>}
                    />
                }/>
                <Route path="/students" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<StudentsListPage/>}
                    />
                }/>
                <Route path="/students/create-student" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<StudentCreatePage/>}
                    />
                }/>
                <Route path="/students/:studentId/edit" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<StudentUpdatePage/>}
                    />
                }/>
                <Route path="/students/:studentId" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<StudentDetailsPage/>}
                    />
                }/>
                <Route path="/cohorts" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<CohortsListPage/>}
                    />
                }/>
                <Route path="/cohorts/create-cohort" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<CohortCreatePage/>}
                    />
                }/>
                <Route path="/cohorts/:cohortId/edit" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<CohortUpdatePage/>}
                    />
                }/>
                <Route path="/cohorts/:cohortId" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<CohortDetailsPage/>}
                    />
                }/>
                <Route path="/connected-apps" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<ConnectedAppsPage/>}
                    />
                }/>
                <Route path="/connected-apps/create-app" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<ConnectedAppCreatePage/>}
                    />
                }/>
                <Route path="/connected-apps/:appId/edit" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<ConnectedAppUpdatePage/>}
                    />
                }/>
                <Route path="/connected-apps/:appId" element={
                    <PrivateRoute
                        authenticationPath={authenticationPath}
                        component={<ConnectedAppDetailsPage/>}
                    />
                }/>
            </Routes>
        </BrowserRouter>
    );
}
