import React from 'react';
import {v4 as uuidv4} from 'uuid';

// Components
import {OptionItem} from '../CustomSelect';
import {Select} from 'antd';

// Styles
import './style.scss';

// Images
import searchIcon from './../../../assets/search-icon.png';

interface MultipleSelectProps {
    options: OptionItem[],
    placeholder: string,
    label: string,
    onSelect: (ids: number[]) => void
}

const MultipleSelect = ({options, placeholder, label, onSelect}: MultipleSelectProps) => {

    return (
        <div className="position-relative">
            {label &&
                <label className="mb-1 fw-bold">
                    {label}
                </label>
            }
            <Select
                mode="multiple"
                style={{width: "100%"}}
                placeholder={placeholder}
                maxTagCount="responsive"
                optionFilterProp="children"
                onChange={onSelect}
                filterOption={(input: string, option: any) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {options.map(item => (
                    <Select.Option value={item.id} key={uuidv4()}>{item.name}</Select.Option>
                ))}
            </Select>
        </div>
    )
}

export default MultipleSelect;