import {LocalStorageKeys} from '../store/keys';
import {PaginationInterface} from '../store/users/reducer';
import {UserRoles} from '../constants';

export const setLocalStorage = (key: LocalStorageKeys, value: string) => localStorage.setItem(key, value);
export const getLocalStorage = (key: LocalStorageKeys) => localStorage.getItem(key);
export const removeLocalStorage = (key: LocalStorageKeys) => localStorage.removeItem(key);
export const makePaginationQueryString = (pagination: PaginationInterface) => {
   return `page=${pagination.page}&per_page=${pagination.perPage}`
};
export const makeFiltersQueryString = (filters: object) => {
    const str: string[] = [];

    Object.entries(filters).map(([key, value]) => {
        str.push(`filter[${key}]=${value}`)
    })

    return str.join('&');
}

export const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString();
}

export const getPages = (currentPage: number, lastPage: number = 0) => {
    if (!currentPage || !lastPage || lastPage === 1) {
        return [];
    }
    const PAGE_RANGE = 3;
    const PLACEHOLDER = '...';
    
    const pages = [];
    const halfPagesCount = Math.floor(PAGE_RANGE / 2);
    let startFrom = Math.max(1, currentPage - halfPagesCount);
    const endPage = Math.min(lastPage, startFrom + (PAGE_RANGE - 1));
    if (endPage === lastPage) {
        startFrom = Math.max(1, endPage - (PAGE_RANGE - 1));
    }

    (startFrom > 1) && pages.push(1);
    (startFrom > 2) && pages.push(PLACEHOLDER);
    pages.push(...(new Array(Math.max((endPage - startFrom + 1), 1)).fill(null).map((_, i) => startFrom + i)));
    (endPage < lastPage - 1) && pages.push(PLACEHOLDER);
    (endPage < lastPage) && pages.push(lastPage);

    return pages;
};

export const getDeviceId = () => {
    let deviceId = localStorage.getItem('DeviceId');

    if (!deviceId) {
        deviceId = crypto.randomUUID();
        localStorage.setItem('DeviceId', deviceId);
    }

    return deviceId;
};

export const isAdmin = (roleName: string) => {
    return [UserRoles.SuperAdmin, UserRoles.CIRSAdmin].includes(roleName);
};

export const isDistrictAdminOrHigher = (roleName: string) => {
    return isAdmin(roleName) || roleName === UserRoles.DistrictAdmin;
};

export const getBackPage = (backPage: string, searchParams: any) => {
    return searchParams.get('backPage') ? searchParams.get('backPage') : backPage;
};

export const schoolItemTabs: {title: string, key: 'students' | 'users'}[] = [
    {
        title: 'Students',
        key: 'students'
    },
    {
        title: 'Users',
        key: 'users'
    }
]
