import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

// Components
import ListTableHeaderComponent from '../../components/ListTableHeader';
import ListTableWrapper from '../../components/ListTable/TableWrapper';
import FiltersComponent from '../../components/Filters';
import { TailSpin } from 'react-loader-spinner';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

// Helpers
import { isAdmin } from '../../helpers';

// Configs
import { getStudentsFilterConfig, getStudentsListColumns } from '../../config/confings';

// Constants
import { archived, grades } from '../../constants';

// Reducers
import { selectDistrictState } from '../../store/districts/reducer';
import { selectCohortState } from '../../store/cohorts/reducer';
import { selectSchoolState } from '../../store/schools/reducer';
import { selectStudentState } from '../../store/students/reducer';
import { selectAuthState } from '../../store/auth/reducer';

// Actions
import {
    deleteStudent,
    getStudents,
    setStudentsFilters,
    setStudentsPagination,
    setStudentsSort
} from '../../store/students/actions';
import { getCohorts, setCohortsPagination, setCohortsSort } from '../../store/cohorts/actions';
import { getDistricts, setDistrictsPagination, setDistrictsSort } from '../../store/districts/actions';
import { getSchools, setSchoolsPagination, setSchoolsSort } from '../../store/schools/actions';

const StudentsListPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { students, pagination, sort, filters } = useAppSelector(selectStudentState);
    const [isLoading, setIsLoading] = useState(true);
    const { authUser } = useAppSelector(selectAuthState);
    const { districts } = useAppSelector(selectDistrictState);
    const { cohorts } = useAppSelector(selectCohortState);
    const { schools } = useAppSelector(selectSchoolState);

    useEffect(() => {
        (async () => {
            dispatch(setCohortsPagination({ "perPage": 100, "page": 1 }));
            dispatch(setCohortsSort("name"));
            dispatch(setDistrictsPagination({ "perPage": 100, "page": 1 }));
            dispatch(setDistrictsSort("name"));
            dispatch(setSchoolsPagination({ "perPage": 100, "page": 1 }));
            dispatch(setSchoolsSort("name"));
            dispatch(setStudentsPagination({ "perPage": 10, "page": 1 }));
            if (isAdmin(authUser?.role?.name)) {
                await dispatch(getCohorts());
            }
            await dispatch(getDistricts());
            await dispatch(getSchools());
            await getList();
            setIsLoading(false);
        })()
        return () => {
            dispatch(setStudentsFilters({}));
            dispatch(setStudentsPagination({ "perPage": 10, "page": 1 }));
            dispatch(setStudentsSort(""));
        }
    }, [])

    const getList = async () => {
        await dispatch(getStudents());
    };

    const setFilters = async (data: object) => {
        dispatch(setStudentsFilters(data));
        await getList();
    };

    const createStudent = () => {
        navigate("/students/create-student", { replace: true });
    };

    const handlePagination = async (name: string, value: number | string) => {
        dispatch(setStudentsPagination({ [name]: +value }));
        if (name === "perPage") {
            dispatch(setStudentsPagination({ "page": 1 }));
        }
        await getList();
    };

    const handleSort = async (value: string) => {
        dispatch(setStudentsSort(value));
        await getList();
    };

    const deleteItem = async (id: number) => {
        await dispatch(deleteStudent(id));
    };

    const viewItem = (id: number) => {
        navigate(`/students/${id}`, { replace: true });
    };

    const editItem = (id: number) => {
        navigate(`/students/${id}/edit`, { replace: true });
    };

    const getTableHeader = () => (
        <ListTableHeaderComponent
            filters={filters}
            handlePagination={handlePagination}
            itemsName="students"
            pagination={pagination}
            setFilters={setFilters}
        />
    );

    return (
        <Container className="mb-4">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-middle">
                                <h1 className="m-0">Students</h1>
                                <Button variant="primary" className="rounded mt-1 d-flex align-items-center"
                                    onClick={() => createStudent()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                    Create Student
                                </Button>
                            </div>
                            <hr />
                            {isLoading
                                ? <div className="d-flex justify-content-center align-items-center my-5">
                                    <TailSpin
                                        height="400"
                                        width="80"
                                        color="#53c154"
                                        visible={true}
                                    />
                                </div>
                                :
                                <>
                                    <FiltersComponent
                                        filterConfig={getStudentsFilterConfig(cohorts, schools, districts, grades, archived, get(authUser, 'role.name'))}
                                        initialFilters={filters}
                                        setFilters={setFilters}
                                    />
                                    <hr />
                                    <ListTableWrapper
                                        pagination={pagination}
                                        handlePagination={handlePagination}
                                        header={getTableHeader()}
                                        handleSort={handleSort}
                                        sort={sort}
                                        listData={students}
                                        columns={getStudentsListColumns(deleteItem, viewItem, editItem)}
                                    />
                                </>

                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

export default StudentsListPage;
