import React from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../assets/cirs-logo.svg';
import { ReactComponent as BeaconLogo } from '../../assets/beacon-logo.svg';
import { ReactComponent as DrcoLogo } from '../../assets/drco-logo.svg';

import './style.scss';

const AccessDeniedPage = () => {

    const logout = () => {
        window.location.href = process.env.REACT_APP_SSO_SERVER_URL + "/logout-user?redirect_url=" + process.env.REACT_APP_URL;
    };

    return (
        <Container id="access-denied-page">
            <Row>
                <Col>
                    <Card className="p-4 mt-4">
                        <Card.Body>
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <Logo className="logo" />
                                <h3 className="mb-4">Please choose an app to continue:</h3>
                                <div className="d-flex align-items-end">
                                    <a href={process.env.REACT_APP_BEACON_URL} className="app-menu-item p-4" title="Beacon for Schools">
                                        <BeaconLogo />
                                    </a>
                                    <a href={process.env.REACT_APP_DRCO_URL} className="app-menu-item p-4" title="Daily Report Card Online">
                                        <DrcoLogo />
                                    </a>
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    <Button variant="outline-primary" onClick={logout}>Logout</Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    )
}

export default AccessDeniedPage;
