import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAlert} from 'react-alert';
import {get, isEmpty} from 'lodash';

// Components
import ListTableWrapper from '../../components/ListTable/TableWrapper';
import AddStudentModal from '../../components/Modals/AddStudentModal';
import DetailsComponent from '../../components/DetailsComponent';
import {Button, Tab, Tabs, Container, Row, Col, Card} from 'react-bootstrap';
import {TailSpin} from 'react-loader-spinner';

// Configs
import {
    getStudentDetailsConfig,
    getStudentUsersConfig
} from '../../config/confings';

// Reducers
import {selectUserState} from '../../store/users/reducer';
import {selectStudentState} from '../../store/students/reducer';

// Actions
import {getUsers, setUsersPagination} from '../../store/users/actions';
import {
    addUsersToStudent,
    getStudent,
    getStudentUsers, removeUserFromStudent,
    setStudentUsersPagination,
    setStudentUsersSort
} from '../../store/students/actions';

// Images
import plusIcon from '../../assets/plus-icon.png';

// Interfaces
import {IUser} from '../../models/IUser';

// Helpers
import {isDistrictAdminOrHigher} from '../../helpers';

const StudentDetailsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {studentId} = useParams();
    const alert = useAlert();
    const {selectedStudent, studentUsers, studentUsersPagination, studentUsersSort} = useAppSelector(selectStudentState);
    const {users} = useAppSelector(selectUserState);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (studentId) {
            (async () => {
                dispatch(setUsersPagination({"perPage": 100, "page": 1}));
                await dispatch(getStudent(studentId));
                await dispatch(getStudentUsers(studentId));
                await dispatch(getUsers());
            })()
        }
    }, []);

    const handlePagination = async (name: string, value: number | string) => {
        dispatch(setStudentUsersPagination({[name]: +value}));
        await getUsersList();
    };

    const handleSort = async (value: string) => {
        dispatch(setStudentUsersSort(value));
        await getUsersList();
    };

    const getUsersList = async () => {
        await dispatch(getStudentUsers(selectedStudent.id));
    };

    const onStudentEdit = () => {
        navigate(`/students/${selectedStudent.id}/edit?backPage=${location.pathname}`, {replace: true});
    };

    const deleteItem = async (id: number) => {
        try {
            await dispatch(removeUserFromStudent(selectedStudent.id, {id}));
            await getUsersList();
            alert.success("User was removed successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    const viewItem = (id: number) => {
        navigate(`/users/${id}`, {replace: true});
    };

    const editItem = (id: number) => {
        navigate(`/users/${id}/edit`, {replace: true});
    };

    const getOptionsForModal = () => {
        let usersForAdd = users;
        if (!isEmpty(selectedStudent.users)) {
            const studentUsersIds: number[] = [];
            selectedStudent.users.map((user: IUser) => {
                studentUsersIds.push(user.id);
            });

            usersForAdd = users.filter((user: IUser) => !studentUsersIds.includes(user.id));
        }

        return usersForAdd.filter((user: IUser) => !isDistrictAdminOrHigher(user.role.name) &&
            get(user, 'school.0.id') == selectedStudent.school_id);
    };

    const getTableHeader = () => {
        const {from, to, total} = studentUsersPagination;
        return (
            <div className="d-flex justify-content-between align-items-baseline">
                <div className="d-flex align-items-baseline flex-column">
                    <h2 className="fw-bold m-0">Users</h2>
                    <div className="me-4">
                        {`Showing ${from}-${to} of ${total} users`}
                    </div>
                </div>
                <div className="d-flex align-items-baseline">
                    <Button variant="primary" className="rounded d-flex align-items-center" onClick={()=> setShowModal(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Link user
                    </Button>
                </div>
            </div>
        )
    };

    const addUsers = async (users: number[]) => {
        try {
            await dispatch(addUsersToStudent(selectedStudent.id, {users}));
            await getUsersList();
            setShowModal(false);
            alert.success("Users were added successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <div>
            {selectedStudent.id != studentId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                : <div>
                    <DetailsComponent
                        onEdit={onStudentEdit}
                        detailsConfig={getStudentDetailsConfig(selectedStudent)}
                    />
                     <Container className="mb-4">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <Tabs defaultActiveKey="users" className="px-4">
                                    <Tab eventKey="users" title="Users" tabClassName="no-border">
                                        <Card>
                                            <Card.Body>
                                                <ListTableWrapper
                                                    pagination={studentUsersPagination}
                                                    handlePagination={handlePagination}
                                                    header={getTableHeader()}
                                                    handleSort={handleSort}
                                                    sort={studentUsersSort}
                                                    listData={studentUsers}
                                                    columns={getStudentUsersConfig(deleteItem, viewItem, editItem)}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
            <AddStudentModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={addUsers}
                options={getOptionsForModal()}
                title="User"
            />
        </div>
    )
};

export default StudentDetailsPage;
