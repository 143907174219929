import React, { useEffect, useState } from 'react';
import { useStateMap } from 'react-us-states';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { get } from 'lodash';

// Components
import ListTableHeaderComponent from '../../components/ListTableHeader';
import ListTableWrapper from '../../components/ListTable/TableWrapper';
import FiltersComponent from '../../components/Filters';
import { TailSpin } from 'react-loader-spinner';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

// Images
import plusIcon from '../../assets/plus-icon.png';

// Configs
import { getSchoolsFilterConfig, getSchoolsListColumns } from '../../config/confings';

// Constants
import { getStates } from '../../constants';

// Reducers
import { selectSchoolState } from '../../store/schools/reducer';
import { selectDistrictState } from '../../store/districts/reducer';
import { selectAuthState } from '../../store/auth/reducer';

// Actions
import { getDistricts, setDistrictsPagination, setDistrictsSort } from '../../store/districts/actions';
import {
    deleteSchool,
    getSchools,
    setSchoolsFilters,
    setSchoolsPagination,
    setSchoolsSort
} from '../../store/schools/actions';


const SchoolsListPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const statesMap = useStateMap();
    const [isLoading, setIsLoading] = useState(true);
    const { schools, filters, pagination, sort } = useAppSelector(selectSchoolState);
    const { authUser } = useAppSelector(selectAuthState);
    const { districts } = useAppSelector(selectDistrictState);

    useEffect(() => {
        (async () => {
            dispatch(setDistrictsPagination({ "perPage": 100, "page": 1 }));
            dispatch(setDistrictsSort("name"));
            dispatch(setSchoolsPagination({ "perPage": 10, "page": 1 }));
            await dispatch(getDistricts());
            await getList();
            setIsLoading(false);
        })()
        return () => {
            dispatch(setSchoolsFilters({}));
            dispatch(setSchoolsPagination({ "perPage": 10, "page": 1 }));
            dispatch(setSchoolsSort(""));
        }
    }, [])

    const getList = async () => {
        await dispatch(getSchools());
    };

    const createSchool = () => {
        navigate("/schools/create-school", { replace: true });
    };

    const setFilters = async (data: object) => {
        dispatch(setSchoolsFilters(data));
        await getList();
    };

    const handlePagination = async (name: string, value: number | string) => {
        dispatch(setSchoolsPagination({ [name]: +value }));
        if (name === "perPage") {
            dispatch(setSchoolsPagination({ "page": 1 }));
        }
        await getList();
    };

    const handleSort = async (value: string) => {
        dispatch(setSchoolsSort(value));
        await getList();
    };

    const deleteItem = async (id: number) => {
        await dispatch(deleteSchool(id));
    };

    const viewItem = (id: number) => {
        navigate(`/schools/${id}`, { replace: true });
    };

    const editItem = (id: number) => {
        navigate(`/schools/${id}/edit`, { replace: true });
    };

    const getTableHeader = () => {
        return (
            <ListTableHeaderComponent
                filters={filters}
                handlePagination={handlePagination}
                itemsName="schools"
                pagination={pagination}
                setFilters={setFilters}
            />
        )
    };

    return (
        <Container className="mb-4">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-middle">
                                <h1 className="m-0">Schools</h1>
                                <Button variant="primary" className="rounded mt-1 d-flex align-items-center" onClick={() => createSchool()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                    Create School
                                </Button>
                            </div>
                            <hr />

                            {isLoading
                                ? <div className="d-flex justify-content-center align-items-center my-5">
                                    <TailSpin
                                        height="400"
                                        width="80"
                                        color="#53c154"
                                        visible={true}
                                    />
                                </div>
                                : <>
                                    <FiltersComponent
                                        filterConfig={getSchoolsFilterConfig(getStates(statesMap), districts, get(authUser, 'role.name'))}
                                        initialFilters={filters}
                                        setFilters={setFilters}
                                    />
                                    <hr />
                                    <ListTableWrapper
                                        pagination={pagination}
                                        handlePagination={handlePagination}
                                        header={getTableHeader()}
                                        handleSort={handleSort}
                                        sort={sort}
                                        listData={schools}
                                        columns={getSchoolsListColumns(deleteItem, viewItem, editItem)}
                                    />
                                </>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

export default SchoolsListPage;
