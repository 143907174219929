import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {isEmpty} from 'lodash';

// Components
import TableFilters from '../ListTable/TableFilters';
import CustomSelect from '../Inputs/CustomSelect';

// Constants
import {perPageOptions} from '../../constants';

// Reducers
import {selectDistrictState} from '../../store/districts/reducer';
import {selectCohortState} from '../../store/cohorts/reducer';
import {selectSchoolState} from '../../store/schools/reducer';
import {PaginationInterface} from '../../store/users/reducer';
import {selectRoleState} from '../../store/roles/reducer';


// Actions
import {getDistricts} from '../../store/districts/actions';
import {getCohorts} from '../../store/cohorts/actions';
import {getSchools} from '../../store/schools/actions';
import {getRoles} from '../../store/roles/actions';

interface ListHeaderInterface {
    pagination: PaginationInterface,
    filters: any,
    setFilters: (data: object) => void,
    handlePagination: (name: string, value: number | string) => void,
    itemsName: string
}

const ListTableHeaderComponent = ({pagination, filters, setFilters, handlePagination, itemsName}: ListHeaderInterface) => {
    const dispatch = useAppDispatch();
    const {districts} = useAppSelector(selectDistrictState);
    const {cohorts} = useAppSelector(selectCohortState);
    const {schools} = useAppSelector(selectSchoolState);
    const {roles} = useAppSelector(selectRoleState);
    const {from, to, total, perPage} = pagination;

    const getFilterName = (data: { id: number, name: string }[], value?: number) => {
        const id = value && +value;
        const element = data.find(item => item.id === id);
        return element?.name;
    };

    const getFiltersValues = () => {
        const values: { name: string; value: any; }[] = [];
        Object.entries(filters).map(([key, value]) => {
            switch (key) {
                case "role":
                    values.push({
                        name: key,
                        value: getFilterName(roles, filters.role)
                    });
                    break;
                case "district":
                    values.push({
                        name: key,
                        value: getFilterName(districts, filters.district)
                    });
                    break;
                case "school":
                    values.push({
                        name: key,
                        value: getFilterName(schools, filters.school)
                    });
                    break;
                case "cohort":
                    values.push({
                        name: key,
                        value: getFilterName(cohorts, filters.cohort)
                    });
                    break;
                default:
                    values.push({
                        name: key,
                        value
                    });
            }
        });

        return values;
    };

    const removeFilter = async (name: string | object) => {
        if (typeof name === "object") {
            setFilters({});
        } else {
            // @ts-ignore
            const {[name]: _, ...rest} = filters;
            setFilters(rest);
        }
    };

    return (
        <div className="d-flex justify-content-between align-items-baseline mt-4">
            <div className="d-flex align-items-baseline">
                <div className="me-4">
                    { total > 0 ? `Showing ${from}-${to} of ${total} ${itemsName}` : 'No results. Try reseting the search filters.'}
                </div>
                <TableFilters
                    removeFilter={removeFilter}
                    filters={getFiltersValues()}/>
            </div>
            <div className="d-flex align-items-baseline">
                <p className="me-2 mb-0">Per page</p>
                <CustomSelect
                    options={perPageOptions}
                    value={perPage}
                    name="perPage"
                    size="small"
                    onChange={handlePagination}
                />
            </div>
        </div>
    )
};

export default ListTableHeaderComponent;
