import React, {ChangeEvent} from 'react';
import './style.scss';

interface CustomCheckboxInterface {
    name: string,
    checked: boolean,
    onChange: (name: string, value: boolean) => void
    label?: string,
    className?: string
}

const CustomCheckbox = ({name, checked, onChange, label, className}: CustomCheckboxInterface) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(name, event.target.checked);
    };

    return (
        <div className="d-flex w-huge mt-3">
            <input
                id={name}
                type="checkbox"
                name={name}
                checked={checked}
                onChange={handleChange}
                className={`custom-checkbox ${className}`}
            />
            {label &&
                <label htmlFor={name}>
                    {label}
                </label>
            }
        </div>
    )
}

export default CustomCheckbox;