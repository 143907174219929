import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useAlert} from 'react-alert';
import {useStateMap} from 'react-us-states';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

// Configs
import {getDistrictEditConfig} from '../../config/confings';

// Constants
import {getStates} from '../../constants';

// Components
import ModelEditComponent from '../../components/EditComponent';
import {TailSpin} from 'react-loader-spinner';

// Actions
import {getDistrict, updateDistrict} from '../../store/districts/actions';

// Reducers
import {selectDistrictState} from '../../store/districts/reducer';

// Helpers
import {getBackPage} from '../../helpers';


const DistrictUpdatePage = () => {
    const dispatch = useAppDispatch();
    const statesMap = useStateMap();
    const {districtId} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const alert = useAlert();
    const {selectedDistrict} = useAppSelector(selectDistrictState);
    const backPage = "/districts";

    useEffect(() => {
        if (districtId && selectedDistrict.id !== districtId) {
            dispatch(getDistrict(districtId));
        }
    }, [])
    const onSave = async (data: any) => {
        try {
            await dispatch(updateDistrict(selectedDistrict.id, data));
            navigate(getBackPage(backPage, searchParams),{replace: true})
            alert.success("District updated successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <div>
            {selectedDistrict.id != districtId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                :
                <ModelEditComponent
                    title="Update District"
                    initialData={selectedDistrict}
                    backPage={backPage}
                    fieldsConfig={getDistrictEditConfig(getStates(statesMap))}
                    onSubmit={onSave}
                />
            }
        </div>
    )
}

export default DistrictUpdatePage;
