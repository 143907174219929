import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../index';

interface ErrorState {
    errors: object;
    message: string
}

const initialState: ErrorState = {
    errors: {},
    message: ""
};

export const errorSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        setError: (state, action:PayloadAction<any>) => {
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.message
            }
        }
    }
});

export const { setError } = errorSlice.actions;
export const selectErrorState = (state: RootState) => state.errors;
export const errorReducer = errorSlice.reducer;