import {loginSuccess, logoutSuccess, getAuthUser, request, setOtpConfig} from './reducer';
import {removeLocalStorage, setLocalStorage} from '../../helpers';
import {LocalStorageKeys} from '../keys';
import {axiosInstance} from '../../config/axios';
import {AppDispatch} from '../index';
import {setError} from '../errors/reducer';
import {get} from 'lodash';


export interface AuthPayload {
    email: string
    password: string,
    device: string
}

export const login = (data: AuthPayload) => async (dispatch: AppDispatch) => {
    try {
       const response = await axiosInstance.post("/login", data);
       dispatch(loginSuccess(response.data?.data.user));
       setLocalStorage(LocalStorageKeys.TOKEN, response?.data?.data.token);
    } catch (err: any) {
        const temporaryToken = get(err, ['response', 'data', 'errors', 'token']);
        if (temporaryToken) {
            setLocalStorage(LocalStorageKeys.TOKEN, temporaryToken);
        }
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getSsoToken = (code: string|null) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get(`/get-sso-access-code?code=${code}`);
        setLocalStorage(LocalStorageKeys.TOKEN, response?.data?.data.token);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getMe = () => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get("/get-me");
        dispatch(getAuthUser(response.data?.data.user));
        setLocalStorage(LocalStorageKeys.TOKEN, response?.data?.data.token);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const logout = () => async (dispatch: AppDispatch) => {
    removeLocalStorage(LocalStorageKeys.TOKEN);
    dispatch(logoutSuccess());
};

export const impersonateUser = (userId: number) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get(`/impersonate-user/${userId}`);
        setLocalStorage(LocalStorageKeys.TOKEN, response?.data?.data.token);
        setLocalStorage(LocalStorageKeys.isImpersonate, 'true');
        await dispatch(getMe());
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setPassword = (data: object) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.post("/set-password", data);
        setLocalStorage(LocalStorageKeys.TOKEN, response?.data?.data.token);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const otpLogin = (data: { one_time_password: string, device: string, remember_me: boolean }) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.post("/login-with-otp", data);
        dispatch(loginSuccess(response.data?.data.user));
        setLocalStorage(LocalStorageKeys.TOKEN, response?.data?.data.token);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getOtpConfigData = () => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get("/otp");
        dispatch(setOtpConfig(response?.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setOtpData = (data: {one_time_password: string|number, otp_secret: string}) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.post("/otp", data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};
