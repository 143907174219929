import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {TailSpin} from 'react-loader-spinner';

// Configs
import {getDistrictDetailsConfig, getSchoolsListColumns} from '../../config/confings';

// Components
import DetailsComponent from '../../components/DetailsComponent';
import ListTableWrapper from "../../components/ListTable/TableWrapper";

// Reducers
import {selectDistrictState} from '../../store/districts/reducer';
import {PaginationInterface} from "../../store/users/reducer";

// Actions
import {
    getDistrict,
    getDistrictSchools, setDistrictSchoolsPagination,
    setDistrictSchoolsSort,
    setDistrictsPagination, setDistrictsSort,
} from '../../store/districts/actions';
import { setStudentsPagination} from "../../store/students/actions";
import {deleteSchool, setSchoolStudentsPagination, setSchoolStudentsSort} from "../../store/schools/actions";

const DistrictDetailsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {districtId} = useParams();
    const {
        selectedDistrict,
        districtSchoolsPagination,
        districtSchoolsSort,
        districtSchools
    } = useAppSelector(selectDistrictState);

    useEffect(() => {
        if (districtId) {
            (async () => {
                await dispatch(getDistrictSchools(districtId))
                await dispatch(getDistrict(districtId));
            })()
        }
    }, [])

    const onEdit = () => {
        navigate(`/districts/${selectedDistrict.id}/edit?backPage=${location.pathname}`, {replace: true});
    };

    const getDistrictSchoolsList = async () => {
        await dispatch(getDistrictSchools(selectedDistrict.id));
    };
    const handlePagination = async (name: string, value: number | string) => {
        dispatch(setDistrictSchoolsPagination({[name]: +value}));
        await getDistrictSchoolsList();
    }

    const handleSort = async (value: string) => {
        dispatch(setDistrictSchoolsSort(value));
        await getDistrictSchoolsList();
    };

    const deleteItem = async (id: number) => {
        await dispatch(deleteSchool(id));
    };

    const viewItem = (id: number) => {
        navigate(`/schools/${id}`, {replace: true});
    };

    const editItem = (id: number) => {
        navigate(`/schools/${id}/edit`, {replace: true});
    };

    const getTableHeader = (name: string, paginationInfo: PaginationInterface) => {
        const {from, to, total} = paginationInfo;
        return (
            <div className="d-flex justify-content-between align-items-baseline">
                <div className="d-flex align-items-baseline flex-column">
                    <h2 className="fw-bold m-0">{name}</h2>
                    <div className="me-4">
                        {`Showing ${from}-${to} of ${total} ${name.toLowerCase()}`}
                    </div>
                </div>
                <div className="d-flex align-items-baseline">
                </div>
            </div>
        )
    };

    return (
        <div>
            {selectedDistrict.id != districtId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                : <div>
                    <DetailsComponent
                        onEdit={onEdit}
                        detailsConfig={getDistrictDetailsConfig(selectedDistrict)}
                    />
                    <Container className="mb-4">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <Tabs defaultActiveKey="schools" className="px-4">
                                        <Tab eventKey="schools" title="Schools" tabClassName="no-border">
                                            <Card>
                                                <Card.Body>
                                                    <ListTableWrapper
                                                        pagination={districtSchoolsPagination}
                                                        handlePagination={handlePagination}
                                                        header={getTableHeader('Schools', districtSchoolsPagination)}
                                                        handleSort={handleSort}
                                                        sort={districtSchoolsSort}
                                                        listData={districtSchools}
                                                        columns={getSchoolsListColumns(deleteItem, viewItem, editItem)}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>
    )
}

export default DistrictDetailsPage;