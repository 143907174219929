import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useNavigate} from 'react-router-dom';
import {useAlert} from 'react-alert';
import {get, isEmpty} from 'lodash';

// Configs
import {getStudentsEditConfig} from '../../config/confings';

// Constants
import {grades} from '../../constants';

// Components
import ModelEditComponent from '../../components/EditComponent';

// Reducers
import {selectSchoolState} from '../../store/schools/reducer';
import {selectCohortState} from '../../store/cohorts/reducer';
import {selectAuthState} from '../../store/auth/reducer';

// Actions
import {createStudent} from '../../store/students/actions';
import {getSchools} from '../../store/schools/actions';
import {getCohorts} from '../../store/cohorts/actions';

// Helpers
import {isDistrictAdminOrHigher} from '../../helpers';

const StudentCreatePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const alert = useAlert();
    const {schools} = useAppSelector(selectSchoolState);
    const {cohorts} = useAppSelector(selectCohortState);
    const {authUser} = useAppSelector(selectAuthState);

    useEffect(() => {
        if (isEmpty(schools)) {
            dispatch(getSchools());
        }
        if (isEmpty(cohorts)) {
            dispatch(getCohorts());
        }
    },[])

    const backPage = "/students";

    const onSave = async (data: any) => {
        if (!isDistrictAdminOrHigher(get(authUser, 'role.name'))) {
            data.school = get(authUser, 'schools.0.id');
        }
        try {
            await dispatch(createStudent({...data, first_name:'first_name', last_name: 'last_name' }));
            navigate(backPage, {replace: true});
            alert.success("Student created successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <ModelEditComponent
            title="Create Student"
            initialData={{}}
            backPage={backPage}
            fieldsConfig={getStudentsEditConfig(schools, cohorts, grades, get(authUser, 'role.name'))}
            onSubmit={onSave}
        />
    )
};

export default StudentCreatePage;
