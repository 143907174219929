import {axiosInstance} from '../../config/axios';
import {
    deleteCohortSuccess,
    getCohortsListSuccess,
    getCohortSuccess, setCohortsFiltersSuccess, setCohortsPaginationSuccess, setCohortsSortSuccess,
    updateCohortSuccess
} from './reducer';
import {AppDispatch, RootState} from '../index';
import {setError} from '../errors/reducer';
import {makeFiltersQueryString, makePaginationQueryString} from '../../helpers';

export const getCohorts = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.cohorts.pagination);
        const filtersQuery = makeFiltersQueryString(currentState.cohorts.filters);
        const response = await axiosInstance.get(`/cohorts?${paginationQuery}&${filtersQuery}&sort=${currentState.cohorts.sort}`);
        dispatch(getCohortsListSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getCohort = (id: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get(`/cohorts/${id}`);
        dispatch(getCohortSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const updateCohort = (id: string, data: object) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.put(`/cohorts/${id}`, data);
        dispatch(updateCohortSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const createCohort = (data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.post("/cohorts", data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const deleteCohort = (id: number) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.delete(`/cohorts/${id}`);
        dispatch(deleteCohortSuccess(id));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setCohortsPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setCohortsPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setCohortsSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setCohortsSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setCohortsFilters = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setCohortsFiltersSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};
