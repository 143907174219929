import {IUser} from '../../models/IUser';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../index';

interface AuthState {
    authUser: IUser | any,
    isLoading: boolean,
    otpConfig: {qrcode_url: string, secret: string} | any
}

const initialState: AuthState = {
    authUser: {},
    isLoading: false,
    otpConfig: {}
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        request: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        loginSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                authUser: action.payload,
                isLoading: false,
            }
        },
        logoutSuccess: (state) => {
            return {
                ...state,
                authUser: {},
                isLoading: false
            }
        },
        getAuthUser: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                authUser: action.payload,
                isLoading: false
            }
        },
        setOtpConfig: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                otpConfig: action.payload
            }
        }
    }
});

export const {request, loginSuccess, logoutSuccess, getAuthUser, setOtpConfig} = authSlice.actions;
export const selectAuthState = (state: RootState) => state.auth;
export const authReducer = authSlice.reducer;