import {AppDispatch, RootState} from '../index';
import {axiosInstance} from '../../config/axios';
import {
    setUserStudentsPaginationSuccess,
    setUserStudentsSortSuccess,
    setUsersPaginationSuccess,
    resetSelectedUserSuccess,
    getUserStudentsSuccess,
    setUsersFiltersSuccess,
    getUsersListSuccess,
    setUsersSortSuccess,
    deleteUserSuccess,
    updateUserSuccess,
    getUserSuccess,
} from './reducer';
import {makeFiltersQueryString, makePaginationQueryString} from '../../helpers';
import {setError} from '../errors/reducer';

export const getUsers = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.users.pagination);
        const filtersQuery = makeFiltersQueryString(currentState.users.filters);
        const response = await axiosInstance.get(`/users?${paginationQuery}&${filtersQuery}&sort=${currentState.users.sort}`);
        dispatch(getUsersListSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setUsersPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setUsersPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setUsersSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setUsersSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setUsersFilters = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setUsersFiltersSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const deleteUser = (id: number) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.delete(`/users/${id}`);
        dispatch(deleteUserSuccess(id));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const createUser = (data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.post('/users', data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const updateUser = (id: string, data: object) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.put(`/users/${id}`, data);
        dispatch(updateUserSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getUser = (id: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get(`/users/${id}`);
        dispatch(getUserSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getUserStudents = (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.users.userStudentsPagination);
        const response = await axiosInstance.get(`/users/${id}/students?${paginationQuery}&sort=${currentState.users.userStudentsSort}`);
        dispatch(getUserStudentsSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setUserStudentsPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setUserStudentsPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setUserStudentsSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setUserStudentsSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const addStudentsToUser = (id: string, data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.put(`/users/${id}/addStudents`, data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const removeStudentFromUser = (id: string, data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.put(`/users/${id}/removeStudent`, data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const resentSetPasswordEmail = (email: string) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.post("/users/resendEmail", {email});
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const resetSelectedUser = () => (dispatch: AppDispatch) => {
    try {
        dispatch(resetSelectedUserSuccess());
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};
