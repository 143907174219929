import React, {useEffect} from 'react';
import {useAlert} from 'react-alert';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useNavigate} from 'react-router-dom';
import {useStateMap} from 'react-us-states';
import {isEmpty} from 'lodash';

// Configs
import {getSchoolEditConfig} from '../../config/confings';

// Constants
import {getStates} from '../../constants';

// Components
import ModelEditComponent from '../../components/EditComponent';

// Reducers
import {selectDistrictState} from '../../store/districts/reducer';

// Actions
import {createSchool} from '../../store/schools/actions';
import {getDistricts} from '../../store/districts/actions';

const SchoolCreatePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const statesMap = useStateMap();
    const alert = useAlert();
    const {districts} = useAppSelector(selectDistrictState);

    useEffect(() => {
        if (isEmpty(districts)) {
            (async () => await dispatch(getDistricts()))();
        }
    },[])

    const backPage = "/schools";

    const onSave = async (data: any) => {
        try {
            const {beacon, drco, ...rest} = data
            const changedData = {
                ...rest,
                app_access: {
                    beacon,
                    drco
                }
            }

            await dispatch(createSchool(changedData));
            navigate(backPage, {replace: true});
            alert.success("School created successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <ModelEditComponent
            title="Create School"
            initialData={{ beacon: true, drco: true }}
            backPage={backPage}
            fieldsConfig={getSchoolEditConfig(getStates(statesMap), districts)}
            onSubmit={onSave}
        />
    )
};

export default SchoolCreatePage;