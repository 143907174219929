import React, {useEffect} from 'react';
import {useAlert} from 'react-alert';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';

// Components
import ModelEditComponent from '../../components/EditComponent';
import {TailSpin} from 'react-loader-spinner';

// Configs
import {getCohortsEditConfig} from '../../config/confings';

// Reducers
import {selectCohortState} from '../../store/cohorts/reducer';

// Actions
import {getCohort, updateCohort} from '../../store/cohorts/actions';

// Helpers
import {getBackPage} from '../../helpers';

const CohortUpdatePage = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const {cohortId} = useParams();
    const navigate = useNavigate();
    const alert = useAlert();
    const {selectedCohort} = useAppSelector(selectCohortState);
    const backPage = "/cohorts";

    useEffect(() => {
        if (cohortId && selectedCohort.id !== cohortId) {
            dispatch(getCohort(cohortId));
        }
    }, [])
    const onSave = async (data: any) => {
        if (data.name === selectedCohort.name) {
            delete data.name;
        }

        try {
            await dispatch(updateCohort(selectedCohort.id, data));
            navigate(getBackPage(backPage, searchParams),{replace: true})
            alert.success("Cohort updated successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <div>
            {selectedCohort.id != cohortId
                ? <div className="d-flex justify-content-center align-items-center my-5">
                    <TailSpin
                        height="400"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                :
                <ModelEditComponent
                    title="Update Cohort"
                    initialData={selectedCohort}
                    backPage={backPage}
                    fieldsConfig={getCohortsEditConfig()}
                    onSubmit={onSave}
                />
            }
        </div>
    )
};

export default CohortUpdatePage;
