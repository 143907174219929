import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Button } from 'react-bootstrap';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import TypeaheadSelect from "../Inputs/TypeaheadSelect";


interface FiltersComponentInterface {
    setFilters: (values: object) => void,
    filterConfig: any,
    initialFilters: object
}

export interface FieldsConfigInterface {
    fieldType: string,
    name: string,
    placeholder: string,
    size: "small" | "medium" | "large" | "huge",
    type?: string,
    options?: any,
    displayKey?: string
}

export interface StateInterface {
    [key: string]: any
}

const FiltersComponent = ({ setFilters, initialFilters, filterConfig }: FiltersComponentInterface) => {
    const [filterValues, setFilterValues] = useState<StateInterface>(initialFilters);
    const details = useRef<any>(initialFilters);

    useEffect(() => {
        setFilterValues(initialFilters);
        details.current = initialFilters;
    }, [initialFilters])

    const onFieldChange = (name: string, value: number | string) => {
        setFilterValues({ ...filterValues, [name]: value });
    };

    const onInputChange = (name: string, value: number | string) => {
        details.current = { ...details.current, [name]: value };
    };

    const resetFilters = () => {
        setFilterValues({});
        setFilters({});
    }

    const getFilterField = (field: FieldsConfigInterface) => {
        const { name, type, placeholder, options, size, displayKey } = field;
        switch (field.fieldType) {
            case "input":
                return <CustomInput
                    name={name}
                    key={uuidv4()}
                    type={type}
                    placeholder={placeholder}
                    value={details.current[name]}
                    onChange={onInputChange}
                    size={size}
                    className="me-4 mt-3"
                />
            case "select":
                return <CustomSelect
                    name={name}
                    key={uuidv4()}
                    size={size}
                    placeholder={placeholder}
                    displayKey={displayKey}
                    options={options}
                    value={filterValues[name]}
                    onChange={onFieldChange}
                    className="me-4 mt-3"
                />
            case "typeahead_select":
                    return <TypeaheadSelect
                        name={name}
                        key={uuidv4()}
                        label={''}
                        placeholder={placeholder + '...'}
                        options={options}
                        displayKey={displayKey}
                        value={filterValues[name]}
                        error={''}
                        onChange={onFieldChange}
                        className="me-4 mt-3"
                    />
        }
    };

    return (
        <div className="d-flex flex-column justify-content-between">
            <form>
                <div className="d-flex flex-wrap mb-3">
                    {filterConfig.map((item: FieldsConfigInterface) => getFilterField(item))}
                </div>
                <div className="d-flex align-self-end mb-3">
                    <Button
                        variant="outline-secondary"
                        className="rounded me-3 h-40"
                        onClick={() => resetFilters()}
                    >
                        Reset
                    </Button>
                    <Button variant="primary" type="submit" className="rounded h-40" onClick={(e:any) => {e.preventDefault(); setFilters({ ...details.current, ...filterValues })}}>
                        Apply
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default FiltersComponent;
