import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../index';
import {ISchool} from '../../models/ISchool';
import {PaginationInterface, UsersFilters} from "../users/reducer";
import {IUser} from "../../models/IUser";
import {IStudent} from "../../models/IStudent";
import {StudentsFilters} from "../students/reducer";

interface SchoolsFilters {
    id?: number,
    name?: string,
    city?: number,
    state?: string,
    district?: number
}

interface SchoolState {
    schools: ISchool[],
    filters: SchoolsFilters,
    studentsAndUsersFilters: StudentsFilters | UsersFilters,
    sort: string,
    pagination: PaginationInterface,
    schoolStudentsPagination: PaginationInterface,
    schoolUsersPagination: PaginationInterface,
    schoolStudentsSort: string,
    schoolUsersSort: string,
    selectedSchool: ISchool | any,
    schoolStudents: IStudent[],
    schoolUsers: IUser[]
}

const initialState: SchoolState = {
    schools: [],
    filters: {},
    studentsAndUsersFilters: {},
    sort: "",
    pagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    schoolStudentsPagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    schoolUsersPagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    schoolStudentsSort: "",
    schoolUsersSort: "",
    selectedSchool: {},
    schoolStudents: [],
    schoolUsers: []
};

export const schoolSlice = createSlice({
    name: 'schools',
    initialState,
    reducers: {
        getSchoolsListSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                schools: action.payload.data,
                pagination: {
                    page: action.payload?.current_page,
                    perPage: action.payload?.per_page,
                    total: action.payload?.total,
                    lastPage: action.payload?.last_page,
                    from: action.payload?.from,
                    to: action.payload?.to
                }
            }
        },
        getSchoolSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedSchool: action.payload
            }
        },
        deleteSchoolSuccess: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                schools: state.schools.filter(item => item.id !== action.payload)
            }
        },
        updateSchoolSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedSchool: action.payload
            }
        },
        setSchoolsPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        },
        setSchoolsSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                sort: action.payload
            }
        },
        setSchoolsFiltersSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                filters: action.payload
            }
        },
        setSchoolStudentsAndUsersFiltersSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                studentsAndUsersFilters: action.payload
            }
        },
        getSchoolStudentsSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                schoolStudents: action.payload.data,
                schoolStudentsPagination: {
                    page: action.payload.current_page,
                    perPage: action.payload.per_page,
                    total: action.payload.total,
                    lastPage: action.payload.last_page,
                    from: action.payload.from ?? 0,
                    to: action.payload.to ?? 0
                }
            }
        },
        setSchoolStudentsPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                schoolStudentsPagination: {
                    ...state.schoolStudentsPagination,
                    ...action.payload
                }
            }
        },
        setSchoolStudentsSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                schoolStudentsSort: action.payload
            }
        },
        getSchoolUsersSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                schoolUsers: action.payload.data,
                schoolUsersPagination: {
                    page: action.payload.current_page,
                    perPage: action.payload.per_page,
                    total: action.payload.total,
                    lastPage: action.payload.last_page,
                    from: action.payload.from ?? 0,
                    to: action.payload.to ?? 0
                }
            }
        },
        setSchoolUsersPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                schoolUsersPagination: {
                    ...state.schoolUsersPagination,
                    ...action.payload
                }
            }
        },
        setSchoolUsersSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                schoolUsersSort: action.payload
            }
        }
    }
});

export const { getSchoolsListSuccess, getSchoolSuccess,
    deleteSchoolSuccess, updateSchoolSuccess,
    setSchoolsPaginationSuccess, setSchoolsSortSuccess,
    setSchoolsFiltersSuccess, setSchoolStudentsAndUsersFiltersSuccess, setSchoolStudentsPaginationSuccess,
    setSchoolStudentsSortSuccess, getSchoolStudentsSuccess,
    getSchoolUsersSuccess, setSchoolUsersPaginationSuccess,
    setSchoolUsersSortSuccess} = schoolSlice.actions;
export const selectSchoolState = (state: RootState) => state.schools;
export const schoolReducer = schoolSlice.reducer;