import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useAlert} from 'react-alert';

// Components
import ModelEditComponent from '../../components/EditComponent';
import {TailSpin} from 'react-loader-spinner';

// Configs
import {getConnectedAppEditConfig} from '../../config/confings';

// Reducers
import {selectAppState} from '../../store/apps/reducer';

// Actions
import {getConnectedApp, updateConnectedApp} from '../../store/apps/actions';

// Helpers
import {getBackPage} from '../../helpers';

const ConnectedAppUpdatePage = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const {appId} = useParams();
    const navigate = useNavigate();
    const alert = useAlert();
    const {selectedApp} = useAppSelector(selectAppState);
    const backPage = "/connected-apps";

    useEffect(() => {
        if (appId && selectedApp.id !== appId) {
            dispatch(getConnectedApp(appId));
        }
    }, [])

    const onSave = async (data: any) => {
        try {
            await dispatch(updateConnectedApp(selectedApp.id, data));
            navigate(getBackPage(backPage, searchParams),{replace: true})
            alert.success("Connected App updated successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <div>
            {selectedApp.id != appId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                :
                <ModelEditComponent
                    title="Update Connected App"
                    initialData={selectedApp}
                    backPage={backPage}
                    fieldsConfig={getConnectedAppEditConfig()}
                    onSubmit={onSave}
                />
            }
        </div>
    )
};

export default ConnectedAppUpdatePage;
