import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useNavigate} from 'react-router-dom';
import {useAlert} from 'react-alert';
import {get, isEmpty} from 'lodash';

// Components
import UserEditBasicPage from '../../components/UserEdit';

// Actions
import {createUser, resetSelectedUser} from '../../store/users/actions';

// Reducers
import {selectUserState} from '../../store/users/reducer';
import {selectAuthState} from '../../store/auth/reducer';

// Constants
import {UserRoles} from '../../constants';

const UserCreatePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {selectedUser} = useAppSelector(selectUserState);
    const {authUser} = useAppSelector(selectAuthState);
    const alert = useAlert();

    useEffect(() => {
        if (!isEmpty(selectedUser)) {
            dispatch(resetSelectedUser());
        }
    },[selectedUser])

    const backPage = "/users";
    const onSubmit = async (data: any) => {
        if (authUser?.role?.name === UserRoles.DistrictAdmin) {
            data.district = get(authUser, 'district.0.id');
        } else if (authUser?.role?.name === UserRoles.SchoolAdmin) {
            data.district = get(authUser, 'district.0.id');
            data.school =get(authUser, 'school.0.id');
        }

        try {
            await dispatch(createUser(data));
            navigate(backPage, {replace: true});
            alert.success("User created successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (

        <UserEditBasicPage
            title="Create User"
            initialData={{}}
            backPage={backPage}
            onSubmit={onSubmit}
        />

    )
}

export default UserCreatePage;
