import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {TailSpin} from 'react-loader-spinner';
import { Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {get, isEmpty} from "lodash";

// Components
import DetailsComponent from '../../components/DetailsComponent';
import ListTableWrapper from "../../components/ListTable/TableWrapper";
import FiltersComponent from "../../components/Filters";
import ListTableHeaderComponent from "../../components/ListTableHeader";

// Configs
import {
    getSchoolDetailsConfig,
    getSchoolStudentsFilterConfig,
    getSchoolUsersFilterConfig,
    getStudentsListColumns,
    getStudentUsersConfig,
} from '../../config/confings';
import {schoolItemTabs} from "../../helpers";
import {archived, grades} from "../../constants";

// Reducers
import {selectSchoolState} from '../../store/schools/reducer';
import {PaginationInterface} from "../../store/users/reducer";
import {selectAuthState} from "../../store/auth/reducer";
import {selectCohortState} from "../../store/cohorts/reducer";
import {selectRoleState} from "../../store/roles/reducer";

// Actions
import {
    getSchool,
    getSchoolStudents,
    getSchoolUsers, setSchoolsFilters, setSchoolStudentsAndUsersFilters,
    setSchoolStudentsPagination,
    setSchoolStudentsSort,
    setSchoolUsersPagination,
    setSchoolUsersSort
} from '../../store/schools/actions';
import {deleteStudent} from "../../store/students/actions";
import {deleteUser} from "../../store/users/actions";

const SchoolDetailsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {schoolId} = useParams();
    const {selectedSchool, schoolStudents, schoolStudentsPagination, schoolStudentsSort, schoolUsersPagination, schoolUsersSort, schoolUsers, studentsAndUsersFilters} = useAppSelector(selectSchoolState);
    const {authUser} = useAppSelector(selectAuthState);
    const {cohorts} = useAppSelector(selectCohortState);
    const {roles} = useAppSelector(selectRoleState);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (schoolId)  {
            (async() => {
                await dispatch(getSchool(schoolId));
                await dispatch(getSchoolStudents(schoolId));
                await dispatch(getSchoolUsers(schoolId));
                clearFilters()
                setIsLoading(false)
            } )()
        }
    },[])

    const setFilters = async (data: object, getList: () => Promise<void>) => {
        dispatch(setSchoolStudentsAndUsersFilters(data));
        await getList();
    };

    const clearFilters = () => {
        if (isEmpty(studentsAndUsersFilters)) {
            dispatch(setSchoolsFilters({}))
        }
    }

    const getStudentsList = async () => {
        await dispatch(getSchoolStudents(selectedSchool.id));
    };

    const getUsersList = async () => {
        await dispatch(getSchoolUsers(selectedSchool.id));
    };

    const onEdit = () => {
        navigate(`/schools/${selectedSchool.id}/edit?backPage=${location.pathname}`, {replace: true});
    };

    const listTableData = {
            students : {
                pagination: schoolStudentsPagination,
                handlePagination: async (name: string, value: number | string) => {
                    dispatch(setSchoolStudentsPagination({[name]: +value}));
                    await getStudentsList();
                },
                handleSort: async (value: string) => {
                    dispatch(setSchoolStudentsSort(value));
                    await getStudentsList();
                },
                sort: schoolStudentsSort,
                listData: schoolStudents,
                config: getStudentsListColumns,
                filterConfig: getSchoolStudentsFilterConfig(cohorts, grades, archived, get(authUser, 'role.name')),
                deleteItem: async(id: number) => {
                        await dispatch(deleteStudent(id));
                        await getStudentsList()
                },
                viewItem: (id: number) => {
                    navigate(`/students/${id}`, {replace: true})
                },
                editItem: (id: number) => {
                    navigate(`/students/${id}/edit`, {replace: true})
                },
                getList: getStudentsList
            },
            users : {
                pagination: schoolUsersPagination,
                handlePagination: async (name: string, value: number | string) => {
                    dispatch(setSchoolUsersPagination({[name]: +value}));
                    await getUsersList();
                },
                handleSort: async (value: string) => {
                    dispatch(setSchoolUsersSort(value));
                    await getUsersList();
                },
                sort: schoolUsersSort,
                listData: schoolUsers,
                config: getStudentUsersConfig,
                filterConfig: getSchoolUsersFilterConfig(roles, cohorts, get(authUser, 'role.name')),
                deleteItem: async(id: number) => {
                    await dispatch(deleteUser(id));
                    await getStudentsList()
                },
                viewItem: (id: number) => {
                    navigate(`/users/${id}`, {replace: true})
                },
                editItem: (id: number) => {
                    navigate(`/users/${id}/edit`, {replace: true})
                },
                getList: getUsersList
            }
        }

    const getTableHeader = (name: string, pagination: PaginationInterface, handlePagination: (name: string, value: number | string) => Promise<void>, getList: () => Promise<void> ) => {
        return (
            <ListTableHeaderComponent
                filters={studentsAndUsersFilters}
                handlePagination={handlePagination}
                itemsName={name}
                pagination={pagination}
                setFilters={(data: object) => setFilters(data, getList)}

            />
        )
    };

    return (
        <div>
            {selectedSchool.id != schoolId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                : <div>
                    <DetailsComponent
                        onEdit={onEdit}
                        detailsConfig={getSchoolDetailsConfig(selectedSchool)}
                    />
                    <Container className="mb-4">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <Tabs defaultActiveKey={schoolItemTabs[0].key}
                                      onSelect={clearFilters}
                                      className="px-4">
                                    {schoolItemTabs.map((item, index) =>
                                        <Tab key={index} eventKey={item.key} title={item.title} tabClassName="no-border">
                                            <Card>
                                                <Card.Body>
                                                    {/* <FiltersComponent
                                                        filterConfig={listTableData[item.key].filterConfig}
                                                        initialFilters={studentsAndUsersFilters}
                                                        setFilters={(data) => setFilters(data, listTableData[item.key].getList)}
                                                    />
                                                    <hr/> */}
                                                    {isLoading
                                                        ? <div className="d-flex justify-content-center align-items-center my-5">
                                                            <TailSpin
                                                                height="400"
                                                                width="80"
                                                                color="#53c154"
                                                                visible={true}
                                                            />
                                                        </div>
                                                        : <ListTableWrapper
                                                            pagination={listTableData[item.key].pagination}
                                                            handlePagination={listTableData[item.key].handlePagination}
                                                            header={getTableHeader(item.title, listTableData[item.key].pagination, listTableData[item.key].handlePagination, listTableData[item.key].getList)}
                                                            handleSort={listTableData[item.key].handleSort}
                                                            sort={listTableData[item.key].sort}
                                                            listData={listTableData[item.key].listData}
                                                            columns={listTableData[item.key].config(listTableData[item.key].deleteItem, listTableData[item.key].viewItem, listTableData[item.key].editItem)}
                                                        />
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                    )}
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>
    )
};

export default SchoolDetailsPage;