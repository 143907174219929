import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../hooks/redux';
import {matchRoutes, Navigate, useLocation} from 'react-router-dom';
import {isEmpty} from 'lodash';
import {routes} from '../config/routeAccess';

// Helpers
import {getLocalStorage} from '../helpers';
import {LocalStorageKeys} from '../store/keys';
import {UserRoles} from '../constants';

// Components
import AccessDeniedPage from '../pages/AccessDenied';
import Header from '../components/Header';

// Reducers
import {selectAuthState} from '../store/auth/reducer';

// Actions
import {getMe} from '../store/auth/actions';

export type ProtectedRouteProps = {
    authenticationPath: string;
    component: JSX.Element
};

const PrivateRoute = ({authenticationPath, component}: ProtectedRouteProps) => {
    const dispatch = useAppDispatch();
    const {authUser} = useAppSelector(selectAuthState);
    const location = useLocation();
    const token = getLocalStorage(LocalStorageKeys.TOKEN);
    // @ts-ignore
    const [{route}] = matchRoutes(routes, location);

    useEffect(() => {
        if (isEmpty(authUser) && token) {
            (async () => await dispatch(getMe()))();
        }
    },[])

    if (!token || (!isEmpty(authUser) && route.canAccess && !route.canAccess.includes(authUser?.role?.name))) {
        return <Navigate to={{pathname: authenticationPath}}/>;
    }

    if (authUser && ![UserRoles.SuperAdmin, UserRoles.CIRSAdmin].includes(authUser?.role?.name) &&
        !getLocalStorage(LocalStorageKeys.isImpersonate)) {
        return <AccessDeniedPage/>
    }

    return <div>
        <Header/>
        {component}
    </div>
}

export default PrivateRoute;
