import {AppDispatch, RootState} from '../index';
import {axiosInstance} from '../../config/axios';
import {
    deleteAppSuccess,
    getAppsListSuccess,
    getAppSuccess, setAppsFiltersSuccess,
    setAppsPaginationSuccess, setAppsSortSuccess,
    updateAppSuccess
} from './reducer';
import {setError} from '../errors/reducer';
import {makeFiltersQueryString, makePaginationQueryString} from '../../helpers';

export const getConnectedApps = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.apps.pagination);
        const filtersQuery = makeFiltersQueryString(currentState.apps.filters);
        const response = await axiosInstance.get(`/connected-apps?${paginationQuery}&${filtersQuery}&sort=${currentState.apps.sort}`);
        dispatch(getAppsListSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getConnectedApp = (id: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get(`/connected-apps/${id}`);
        dispatch(getAppSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const updateConnectedApp = (id: string, data: object) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.put(`/connected-apps/${id}`, data);
        dispatch(updateAppSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const createConnectedApp = (data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.post("/connected-apps", data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const deleteConnectedApp = (id: number) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.delete(`/connected-apps/${id}`);
        dispatch(deleteAppSuccess(id));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setConnectedAppsPagination = (name: string, value: number) => (dispatch: AppDispatch) => {
    try {
        dispatch(setAppsPaginationSuccess({[name]: value}));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setConnectedAppsSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setAppsSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setConnectedAppsFilters = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setAppsFiltersSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};