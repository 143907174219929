import {AppDispatch, RootState} from '../index';
import {axiosInstance} from '../../config/axios';
import {
    deleteDistrictSuccess, getDistrictSchoolsSuccess,
    getDistrictsListSuccess, getDistrictSuccess, setDistrictSchoolsPaginationSuccess, setDistrictSchoolsSortSuccess,
    setDistrictsFiltersSuccess,
    setDistrictsPaginationSuccess,
    setDistrictsSortSuccess, updateDistrictSuccess
} from './reducer';
import {setError} from '../errors/reducer';
import {makeFiltersQueryString, makePaginationQueryString} from '../../helpers';
import {getSchoolUsersSuccess, setSchoolUsersPaginationSuccess, setSchoolUsersSortSuccess} from "../schools/reducer";

export const getDistricts = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.districts.pagination);
        const filtersQuery = makeFiltersQueryString(currentState.districts.filters);
        const response = await axiosInstance.get(`/districts?${paginationQuery}&${filtersQuery}&sort=${currentState.districts.sort}`);
        dispatch(getDistrictsListSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setDistrictsPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setDistrictsPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setDistrictsSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setDistrictsSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setDistrictsFilters = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setDistrictsFiltersSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const deleteDistrict = (id: number) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.delete(`/districts/${id}`);
        dispatch(deleteDistrictSuccess(id));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const createDistrict = (data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.post('/districts', data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const updateDistrict = (id: number, data: object) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.put(`/districts/${id}`, data);
        dispatch(updateDistrictSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getDistrict = (id: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get(`/districts/${id}`);
        dispatch(getDistrictSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
}
export const setDistrictSchoolsPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setDistrictSchoolsPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setDistrictSchoolsSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setDistrictSchoolsSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getDistrictSchools = (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.districts.districtSchoolsPagination);
        const response = await axiosInstance.get(`/districts/${id}/schools?${paginationQuery}&sort=${currentState.districts.districtSchoolsSort}`);
        dispatch(getDistrictSchoolsSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};
