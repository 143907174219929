import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../index';
import {IApp} from '../../models/IApp';
import {PaginationInterface} from '../users/reducer';

interface AppsFilters {
    id?: number,
    name?: string
}

interface AppState {
    apps: IApp[],
    filters: AppsFilters,
    sort: string,
    pagination: PaginationInterface,
    selectedApp: IApp | any
}

const initialState: AppState = {
    apps: [],
    filters: {},
    sort: "",
    pagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    selectedApp: {}
};

export const appSlice = createSlice({
    name: 'apps',
    initialState,
    reducers: {
        getAppsListSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                apps: action.payload.data,
                pagination: {
                    page: action.payload?.current_page,
                    perPage: action.payload?.per_page,
                    total: action.payload?.total,
                    lastPage: action.payload?.last_page,
                    from: action.payload?.from,
                    to: action.payload?.to
                }
            }
        },
        getAppSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedApp: action.payload
            }
        },
        updateAppSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedApp: action.payload
            }
        },
        deleteAppSuccess: (state, action: PayloadAction<string|number>) => {
            const id = action.payload;
            return {
                ...state,
                apps: state.apps.filter(item => item.id !== id)
            }
        },
        setAppsPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        },
        setAppsSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                sort: action.payload
            }
        },
        setAppsFiltersSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                filters: action.payload
            }
        }
    }
});

export const { getAppsListSuccess, getAppSuccess, updateAppSuccess, deleteAppSuccess, setAppsPaginationSuccess,
    setAppsSortSuccess, setAppsFiltersSuccess } = appSlice.actions;
export const selectAppState = (state: RootState) => state.apps;
export const appReducer = appSlice.reducer;