import {IDistrict} from '../../models/IDistrict';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../index';
import {PaginationInterface} from '../users/reducer';
import {ISchool} from "../../models/ISchool";

interface DistrictsFilters {
    id?: number,
    name?: string,
    city?: number,
    state?: string
}

interface DistrictState {
    districts: IDistrict[],
    filters: DistrictsFilters,
    sort: string,
    pagination: PaginationInterface,
    districtSchoolsPagination: PaginationInterface,
    selectedDistrict: IDistrict | any,
    districtSchoolsSort: string,
    districtSchools: ISchool[]
}

const initialState: DistrictState = {
    districts: [],
    filters: {},
    sort: "",
    pagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    districtSchoolsPagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    districtSchoolsSort: "",
    selectedDistrict: {},
    districtSchools: []
};

export const districtSlice = createSlice({
    name: 'districts',
    initialState,
    reducers: {
        getDistrictsListSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                districts: action.payload.data,
                pagination: {
                    page: action.payload?.current_page,
                    perPage: action.payload?.per_page,
                    total: action.payload?.total,
                    lastPage: action.payload?.last_page,
                    from: action.payload?.from,
                    to: action.payload?.to
                }
            }
        },
        getDistrictSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedDistrict: action.payload
            }
        },
        setDistrictsPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        },
        setDistrictsSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                sort: action.payload
            }
        },
        setDistrictsFiltersSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                filters: action.payload
            }
        },
        deleteDistrictSuccess: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                districts: state.districts.filter(item => item.id !== action.payload)
            }
        },
        updateDistrictSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedDistrict: action.payload
            }
        },
        getDistrictSchoolsSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                districtSchools: action.payload.data,
                districtSchoolsPagination: {
                    page: action.payload.current_page,
                    perPage: action.payload.per_page,
                    total: action.payload.total,
                    lastPage: action.payload.last_page,
                    from: action.payload.from ?? 0,
                    to: action.payload.to ?? 0
                }
            }
        },
        setDistrictSchoolsPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                districtSchoolsPagination: {
                    ...state.districtSchoolsPagination,
                    ...action.payload
                }
            }
        },
        setDistrictSchoolsSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                districtSchoolsSort: action.payload
            }
        }
    }
});

export const { getDistrictsListSuccess, setDistrictsPaginationSuccess, setDistrictsSortSuccess,
    setDistrictsFiltersSuccess, deleteDistrictSuccess, updateDistrictSuccess,
    getDistrictSuccess, getDistrictSchoolsSuccess, setDistrictSchoolsPaginationSuccess,
    setDistrictSchoolsSortSuccess } = districtSlice.actions;
export const selectDistrictState = (state: RootState) => state.districts;
export const districtReducer = districtSlice.reducer;