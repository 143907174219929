import {AppDispatch, RootState} from '../index';
import {axiosInstance} from '../../config/axios';
import {
    setStudentsPaginationSuccess,
    setStudentsFiltersSuccess,
    getStudentsListSuccess,
    setStudentsSortSuccess,
    deleteStudentSuccess,
    updateStudentSuccess,
    getStudentSuccess,
    setStudentUsersPaginationSuccess,
    setStudentUsersSortSuccess,
    getStudentUsersSuccess
} from './reducer';
import {setError} from '../errors/reducer';
import {makeFiltersQueryString, makePaginationQueryString} from '../../helpers';

export const getStudents = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.students.pagination);
        const filtersQuery = makeFiltersQueryString(currentState.students.filters);
        const response = await axiosInstance.get(`/students?${paginationQuery}&${filtersQuery}&sort=${currentState.students.sort}`);
        dispatch(getStudentsListSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setStudentsPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setStudentsPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setStudentsSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setStudentsSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setStudentsFilters = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setStudentsFiltersSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const deleteStudent = (id: number) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.delete(`/students/${id}`);
        dispatch(deleteStudentSuccess(id));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const createStudent = (data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.post('/students', data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const updateStudent = (id: string, data: object) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.put(`/students/${id}`, data);
        dispatch(updateStudentSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getStudent = (id: string) => async (dispatch:  AppDispatch) => {
    try {
        const response = await axiosInstance.get(`/students/${id}`);
        dispatch(getStudentSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getStudentUsers = (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.students.studentUsersPagination);
        const response = await axiosInstance.get(`/students/${id}/users?${paginationQuery}&sort=${currentState.students.studentUsersSort}`);
        dispatch(getStudentUsersSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setStudentUsersPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setStudentUsersPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setStudentUsersSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setStudentUsersSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const addUsersToStudent = (id: string, data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.put(`/students/${id}/addUsers`, data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const removeUserFromStudent = (id: string, data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.put(`/students/${id}/removeUser`, data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};
