import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRole} from '../../models/IRole';
import {RootState} from '../index';

interface RoleState {
    roles: IRole[];
}

const initialState: RoleState = {
    roles: []
};

export const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        getRolesListSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                roles: action.payload
            }
        }
    }
});

export const { getRolesListSuccess } = roleSlice.actions;
export const selectRoleState = (state: RootState) => state.roles;
export const roleReducer = roleSlice.reducer;