import React from 'react';

// Components
import {Button, Modal} from "react-bootstrap";

// Styles
import './style.scss';

interface ModalProps {
    show: boolean,
    onClose: () => void,
    onSubmit: () => void,
    bodyText: string,
    confirmText: string
}

const ConfirmationModal = ({show, onClose, onSubmit, bodyText, confirmText}: ModalProps) => {

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Title>
                <h3>Confirmation</h3>
            </Modal.Title>
            <Modal.Body>
                <p>{bodyText}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-secondary"
                    className="rounded h-40"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="danger"
                    className="rounded h-40"
                    onClick={onSubmit}
                >
                    {confirmText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal;