import React from 'react';
import {Modal} from 'react-bootstrap';

// Styles
import './style.scss';

interface ModalProps {
    show: boolean,
    onAvatarCheck: (value: number) => void,
    close: () => void,
}

const AvatarsPopover = ({show, close, onAvatarCheck}: ModalProps) => {
    const avatars = Array.from(Array(24).keys());
    return (
        <Modal show={show} onHide={close}>
            <Modal.Title className='avatar-title'>
                Choose an avatar
            </Modal.Title>
            <Modal.Body>
                <div className="d-flex flex-wrap justify-content-around pe-3 ps-3">
                    {avatars.map(item => (
                        <img
                            src={require(`./../../../assets/avatars/avatar-${item+1}.svg`)}
                            alt={`avatar-${item+1}`}
                            width="50"
                            height="50"
                            className="cursor-pointer me-4 mt-4"
                            onClick={() => onAvatarCheck(item+1)}
                        />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AvatarsPopover;
