import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

// Components
import DetailsComponent from '../../components/DetailsComponent';
import {TailSpin} from 'react-loader-spinner';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';

// Configs
import {getCohortDetailsConfig} from '../../config/confings';

// Reducers
import {selectCohortState} from '../../store/cohorts/reducer';

// Actions
import {getCohort} from '../../store/cohorts/actions';

const CohortDetailsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {cohortId} = useParams();
    const {selectedCohort} = useAppSelector(selectCohortState);

    useEffect(() => {
        if (cohortId) {
            dispatch(getCohort(cohortId));
        }
    },[])

    const onEdit = () => {
        navigate(`/cohorts/${selectedCohort.id}/edit?backPage=${location.pathname}`, {replace: true});
    };

    return (
        <div>
            {selectedCohort.id != cohortId
                ? 
                <div className="d-flex justify-content-center align-items-center my-5">
                    <TailSpin
                        height="400"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                : 
                <DetailsComponent
                    onEdit={onEdit}
                    detailsConfig={getCohortDetailsConfig(selectedCohort)}
                />
            }
        </div>
    )
};

export default CohortDetailsPage;