import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PaginationInterface} from '../users/reducer';
import {IStudent} from '../../models/IStudent';
import {IUser} from '../../models/IUser';
import {RootState} from '../index';

export interface StudentsFilters {
    id?: number,
    first_name?: string,
    last_name?: string,
    grade?: number,
    cohort?: number,
    school?: number,
    district?: number
}

interface StudentState {
    students: IStudent[];
    selectedStudent: IStudent | any,
    pagination: PaginationInterface,
    studentUsersPagination: PaginationInterface,
    filters: StudentsFilters,
    sort: string,
    studentUsersSort: string,
    studentUsers: IUser[]
}

const initialState: StudentState = {
    students: [],
    pagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    studentUsersPagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    selectedStudent: {},
    studentUsers: [],
    sort: "",
    studentUsersSort: "",
    filters: {}
};

export const studentSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        getStudentsListSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                students: action.payload?.data,
                pagination: {
                    page: action.payload?.current_page,
                    perPage: action.payload?.per_page,
                    total: action.payload?.total,
                    lastPage: action.payload?.last_page,
                    from: action.payload?.from,
                    to: action.payload?.to
                }
            }
        },
        setStudentsPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        },
        setStudentsSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                sort: action.payload
            }
        },
        setStudentsFiltersSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                filters: action.payload
            }
        },
        deleteStudentSuccess: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                students: state.students.filter(item => item.id !== action.payload)
            }
        },
        updateStudentSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedStudent: action.payload
            }
        },
        getStudentSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedStudent: action.payload
            }
        },
        getStudentUsersSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                studentUsers: action.payload.data,
                studentUsersPagination: {
                    page: action.payload.current_page,
                    perPage: action.payload.per_page,
                    total: action.payload.total,
                    lastPage: action.payload.last_page,
                    from: action.payload.from ?? 0,
                    to: action.payload.to ?? 0
                }
            }
        },
        setStudentUsersPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                studentUsersPagination: {
                    ...state.studentUsersPagination,
                    ...action.payload
                }
            }
        },
        setStudentUsersSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                studentUsersSort: action.payload
            }
        }
    }
});

export const { getStudentsListSuccess, setStudentsPaginationSuccess, setStudentsSortSuccess, setStudentsFiltersSuccess,
    deleteStudentSuccess, updateStudentSuccess, getStudentSuccess, getStudentUsersSuccess,
    setStudentUsersPaginationSuccess, setStudentUsersSortSuccess } = studentSlice.actions;
export const selectStudentState = (state: RootState) => state.students;
export const studentReducer = studentSlice.reducer;