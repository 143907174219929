import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { get, isEmpty } from 'lodash';

// Components
import UserEditBasicPage from '../../components/UserEdit';
import { TailSpin } from 'react-loader-spinner';

// Actions
import { getUser, updateUser } from '../../store/users/actions';

// Reducers
import { selectUserState } from '../../store/users/reducer';

// Interfaces
import { IApp } from '../../models/IApp';

// Helpers
import { getBackPage } from '../../helpers';

const UserUpdatePage = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const alert = useAlert();
    const { userId } = useParams();
    const navigate = useNavigate();
    const { selectedUser } = useAppSelector(selectUserState);
    const backPage = "/users";

    useEffect(() => {
        if (userId && selectedUser.id !== userId) {
            console.log('dispatch getUser');
            dispatch(getUser(userId));
        }
    }, [])

    const onSubmit = async (data: any) => {
        if (data.email === selectedUser.email) {
            delete data.email;
        }

        try {
            await dispatch(updateUser(selectedUser.id, data));
            navigate(getBackPage(backPage, searchParams), { replace: true })
            alert.success("User updated successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    const getInitialData = () => {
        if (isEmpty(selectedUser)) {
            return {};
        }
        const data: any = {
            id: selectedUser.id,
            first_name: selectedUser.first_name,
            last_name: selectedUser.last_name,
            email: selectedUser.email
        };
        data.role = selectedUser?.role?.name;
        data.district = get(selectedUser, ['district', '0', 'id']);
        data.school = get(selectedUser, ['school', '0', 'id']);
        data.cohort = get(selectedUser, ['cohorts', '0', 'id']);
        selectedUser.connected_apps.map((app: IApp) => {
            data.apps = { ...data.apps, [app.id]: true }
        })

        return data;
    };

    return (
        <div>
            {selectedUser.id != userId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                :

                <UserEditBasicPage
                    title="Update User"
                    initialData={getInitialData()}
                    backPage={backPage}
                    onSubmit={onSubmit}
                />

            }
        </div>
    )
}

export default UserUpdatePage;
