import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {isEmpty} from 'lodash';

// Images
import deleteIcon from '../../../assets/delete-icon.png';

interface TableFiltersProps {
    removeFilter: (name: string | object) => void,
    filters: { name: string, value: any }[],
}

const TableFilters = ({filters, removeFilter}: TableFiltersProps) => {
    return (
        <div className="d-flex">
            {filters.map((filter) => {
                return (
                    <div key={uuidv4()}
                         className="d-flex rounded border border-2 border-light-gray px-2 h-32 me-3 align-items-center">
                        <p className="mb-0 me-1">{filter.value}</p>
                        <img
                            src={deleteIcon}
                            alt="remove"
                            onClick={() => removeFilter(filter.name)}
                            className="cursor-pointer"
                        />
                    </div>
                );
            })}
            {!isEmpty(filters) &&
                <div
                    className="d-flex rounded border border-2 border-light-gray px-2 h-32 cursor-pointer align-items-center"
                    onClick={() => removeFilter({})}
                >
                    <p className="m-0">Clear All</p>
                </div>
            }
        </div>
    )
}

export default TableFilters;
