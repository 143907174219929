import {AppDispatch} from '../index';
import {axiosInstance} from '../../config/axios';
import {getRolesListSuccess} from './reducer';

export const getRoles = () => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get("/roles");
        dispatch(getRolesListSuccess(response.data?.data));
    } catch (err) {
        // dispatch(error(err));
    }
}