import React, {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/redux';
import {useForm} from 'react-hook-form';
import {useAlert} from 'react-alert';
import {isEmpty} from 'lodash';

// Components
import {Button, Form, Card} from 'react-bootstrap';
import {ReactComponent as Logo} from '../../assets/cirs-logo.svg';

// Actions
import {setPassword} from '../../store/auth/actions';

interface ValuesInterface {
    password?: string,
    confirmPassword?: string
}

const SetPasswordPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const alert = useAlert();
    const [searchParams] = useSearchParams();
    const {register, handleSubmit} = useForm();
    const [errors, setErrors] = useState<ValuesInterface>({});

    const onPasswordSubmit = async (values: ValuesInterface) => {
        if (isEmpty(values.password)) {
            setErrors({password: "This field is required"});
            return;
        }
        if (values.password !== values.confirmPassword) {
            setErrors({confirmPassword: "Your password and confirmation password do not match."});
            return;
        }

        const data = {
            confirm_token: searchParams.get('token'),
            password: values.password
        };

        try {
            await dispatch(setPassword(data));
            navigate("/users", {replace: true});
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center login-page">
            <Card>
                <Card.Body>
                    <Logo />
                    <div>
                            <h3>Create a password</h3>
                            <p>Welcome! Please set a new password for your account now.</p>
                            <form onSubmit={handleSubmit(onPasswordSubmit)} className="rounded p-6 bg-white">
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        {...register('password')}
                                    />
                                    {errors.password &&
                                        <p className="error">{errors.password}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm password"
                                        {...register('confirmPassword')}
                                    />
                                    {errors.confirmPassword &&
                                        <p className="error">{errors.confirmPassword}</p>
                                    }
                                </Form.Group>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                    >
                                        Set password
                                    </Button>
                                </div>
                            </form>
                        </div>
                </Card.Body>
            </Card>
        </div>
    )
};

export default SetPasswordPage;
