import {AppDispatch, RootState} from '../index';
import {axiosInstance} from '../../config/axios';
import {
    setSchoolsPaginationSuccess,
    setSchoolsFiltersSuccess,
    setSchoolsSortSuccess,
    getSchoolsListSuccess,
    deleteSchoolSuccess,
    updateSchoolSuccess,
    getSchoolSuccess,
    setSchoolStudentsPaginationSuccess,
    setSchoolStudentsSortSuccess,
    getSchoolStudentsSuccess,
    getSchoolUsersSuccess,
    setSchoolUsersPaginationSuccess,
    setSchoolUsersSortSuccess, setSchoolStudentsAndUsersFiltersSuccess,
} from './reducer';
import {setError} from '../errors/reducer';
import {makeFiltersQueryString, makePaginationQueryString} from '../../helpers';

export const getSchools = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.schools.pagination);
        const filtersQuery = makeFiltersQueryString(currentState.schools.filters);
        const response = await axiosInstance.get(`/schools?${paginationQuery}&${filtersQuery}&sort=${currentState.schools.sort}`);
        dispatch(getSchoolsListSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getSchool = (id: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get(`/schools/${id}`);
        dispatch(getSchoolSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const updateSchool = (id: number, data: object) => async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.put(`/schools/${id}`, data);
        dispatch(updateSchoolSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const createSchool = (data: object) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.post('/schools', data);
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const deleteSchool = (id: number) => async (dispatch: AppDispatch) => {
    try {
        await axiosInstance.delete(`/schools/${id}`);
        dispatch(deleteSchoolSuccess(id));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setSchoolsPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setSchoolsPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setSchoolsSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setSchoolsSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setSchoolsFilters = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setSchoolsFiltersSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
}

export const setSchoolStudentsAndUsersFilters = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setSchoolStudentsAndUsersFiltersSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
}

export const getSchoolStudents = (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.schools.schoolStudentsPagination);
        const filtersQuery = makeFiltersQueryString(currentState.schools.filters);
        const response = await axiosInstance.get(`/schools/${id}/students?${paginationQuery}&${filtersQuery}&sort=${currentState.schools.schoolStudentsSort}`);
        dispatch(getSchoolStudentsSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setSchoolStudentsPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setSchoolStudentsPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setSchoolStudentsSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setSchoolStudentsSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setSchoolUsersPagination = (data: object) => (dispatch: AppDispatch) => {
    try {
        dispatch(setSchoolUsersPaginationSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const setSchoolUsersSort = (data: string) => (dispatch: AppDispatch) => {
    try {
        dispatch(setSchoolUsersSortSuccess(data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};

export const getSchoolUsers = (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const currentState = getState();
        const paginationQuery = makePaginationQueryString(currentState.schools.schoolUsersPagination);
        const filtersQuery = makeFiltersQueryString(currentState.schools.filters);
        const response = await axiosInstance.get(`/schools/${id}/users?${paginationQuery}&${filtersQuery}&sort=${currentState.schools.schoolUsersSort}`);
        dispatch(getSchoolUsersSuccess(response.data?.data));
    } catch (err: any) {
        dispatch(setError(err.response.data));
        throw err;
    }
};
