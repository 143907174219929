import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useAlert} from 'react-alert';
import {get, isEmpty} from 'lodash';

// Styles
import './style.scss';

// Actions
import {
    addStudentsToUser,
    getUser,
    getUserStudents, removeStudentFromUser,
    setUserStudentsPagination,
    setUserStudentsSort
} from '../../store/users/actions';
import {getStudents, setStudentsPagination} from '../../store/students/actions';


// Reducers
import {selectUserState} from '../../store/users/reducer';
import {selectStudentState} from '../../store/students/reducer';

// Configs
import {getUserDetailsConfig, getUserStudentsConfig} from '../../config/confings';

// Components
import ListTableWrapper from '../../components/ListTable/TableWrapper';
import AddStudentModal from '../../components/Modals/AddStudentModal';
import DetailsComponent from '../../components/DetailsComponent';
import {Button, Tab, Tabs, Container, Row, Col, Card} from 'react-bootstrap';
import {TailSpin} from 'react-loader-spinner';

// Images
import plusIcon from '../../assets/plus-icon.png';

// Interfaces
import {IStudent} from '../../models/IStudent';

const UserDetailsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {userId} = useParams();
    const alert = useAlert();
    const {selectedUser, userStudents, userStudentsPagination, userStudentsSort} = useAppSelector(selectUserState);
    const {students} = useAppSelector(selectStudentState);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (userId) {
            (async () => {
                dispatch(setStudentsPagination({"perPage": 100, "page": 1}));
                await dispatch(getUser(userId));
                await dispatch(getUserStudents(userId));
                await dispatch(getStudents());
            })()
        }
    }, [])

    const getStudentsList = async () => {
        await dispatch(getUserStudents(selectedUser.id));
    }

    const handlePagination = async (name: string, value: number | string) => {
        dispatch(setUserStudentsPagination({[name]: +value}));
        await getStudentsList();
    };

    const handleSort = async (value: string) => {
        dispatch(setUserStudentsSort(value));
        await getStudentsList();
    };

    const deleteStudent = async (id: number) => {
        try {
            await dispatch(removeStudentFromUser(selectedUser.id, {id}));
            await getStudentsList();
            alert.success("Student was removed successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    const viewStudent = (id: number) => {
        navigate(`/students/${id}`, {replace: true});
    };

    const editStudent = (id: number) => {
        navigate(`/students/${id}/edit`, {replace: true});
    };

    const addStudents = async (students: number[]) => {
        try {
            await dispatch(addStudentsToUser(selectedUser.id, {students}));
            await getStudentsList();
            setShowModal(false);
            alert.success("Students were added successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    const getTableHeader = () => {
        const {from, to, total} = userStudentsPagination;
        return (
            <div className="d-flex justify-content-between align-items-baseline">
                <div className="d-flex align-items-baseline flex-column">
                    <h2 className="fw-bold m-0">Students</h2>
                    <div className="me-4">
                        {`Showing ${from}-${to} of ${total} students`}
                    </div>
                </div>
                <div className="d-flex align-items-baseline">
                    <Button variant="primary" className="rounded d-flex align-items-center" onClick={()=> setShowModal(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Link student
                    </Button>
                </div>
            </div>
        )
    };

    const onUserEdit = () => {
        navigate(`/users/${selectedUser.id}/edit?backPage=${location.pathname}`, {replace: true});
    };

    const getOptionsForModal = () => {
        let studentsForAdd = students;
        if (!isEmpty(selectedUser.students)) {
            const userStudentsIds: number[] = [];
            selectedUser.students.map((student: IStudent) => {
                userStudentsIds.push(student.id);
            });

            studentsForAdd =  students.filter((student: IStudent) => !userStudentsIds.includes(student.id));
        }

        return studentsForAdd.filter((student: IStudent) => get(selectedUser, 'school.0.id') == student.school_id);
    };

    return (
        <div>
            {selectedUser.id != userId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                : <div>
                    <DetailsComponent
                        onEdit={onUserEdit}
                        detailsConfig={getUserDetailsConfig(selectedUser)}
                    />
                    <Container className="mb-4">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <Tabs defaultActiveKey="students" className="px-4">
                                    <Tab eventKey="students" title="Students" tabClassName="no-border">
                                        <Card>
                                            <Card.Body>
                                                <ListTableWrapper
                                                    pagination={userStudentsPagination}
                                                    handlePagination={handlePagination}
                                                    header={getTableHeader()}
                                                    handleSort={handleSort}
                                                    sort={userStudentsSort}
                                                    listData={userStudents}
                                                    columns={getUserStudentsConfig(deleteStudent, viewStudent, editStudent)}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
            <AddStudentModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={addStudents}
                options={getOptionsForModal()}
                title="Student"
            />
        </div>
    )
};

export default UserDetailsPage;
