import React from 'react';
import {useStateMap} from 'react-us-states';
import {useAlert} from 'react-alert';
import {useAppDispatch} from '../../hooks/redux';
import {useNavigate} from 'react-router-dom';

// Configs
import {getDistrictEditConfig} from '../../config/confings';

// Components
import ModelEditComponent from '../../components/EditComponent';

// Constants
import {getStates} from '../../constants';

// Actions
import {createDistrict} from '../../store/districts/actions';


const DistrictCreatePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const alert = useAlert();
    const statesMap = useStateMap();
    const backPage = "/districts";

    const onSave = async (data: any) => {
        try {
            await dispatch(createDistrict(data));
            navigate(backPage, {replace: true});
            alert.success("District created successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    return (
        <ModelEditComponent
            title="Create District"
            initialData={{}}
            backPage={backPage}
            fieldsConfig={getDistrictEditConfig(getStates(statesMap))}
            onSubmit={onSave}
        />
    )
}

export default DistrictCreatePage;