import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/redux';
import './style.scss';

// Actions
import {getMe, getSsoToken} from '../../store/auth/actions';

// Helpers
import {removeLocalStorage} from '../../helpers';
import {LocalStorageKeys} from '../../store/keys';

const SsoCallbackComponent = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        removeLocalStorage(LocalStorageKeys.isImpersonate);
        if (searchParams.get("state") !== localStorage.getItem("state")) {
            navigate("/login");
        }

        (async () => {
            await dispatch(getSsoToken(searchParams.get("code")));
            await dispatch(getMe());
            navigate("/users");
        })()
    }, [])

    return (
        <div className="sso-loading">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            <h3>Authentication in progress...</h3>
        </div>
    )
}

export default SsoCallbackComponent;
