import {SystemAdmins, UserRoles} from '../constants';

export const routes = [
    {
        path: "/users",
    },
    {
        path: "/users/create-user",
        canAccess: [...SystemAdmins, UserRoles.DistrictAdmin, UserRoles.SchoolAdmin]
    },
    {
        path: "/users/:userId",
    },
    {
        path: "/users/:userId/edit",
        canAccess: [...SystemAdmins, UserRoles.DistrictAdmin, UserRoles.SchoolAdmin]
    },
    {
        path: "/districts",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/districts/create-district",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/districts/:districtId/edit",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/districts/:districtId",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/schools",
        canAccess: [...SystemAdmins, UserRoles.DistrictAdmin]
    },
    {
        path: "/schools/create-school",
        canAccess: [...SystemAdmins, UserRoles.DistrictAdmin]
    },
    {
        path: "/schools/:schoolId/edit",
        canAccess: [...SystemAdmins, UserRoles.DistrictAdmin]
    },
    {
        path: "/schools/:schoolId",
        canAccess: [...SystemAdmins, UserRoles.DistrictAdmin]
    },
    {
        path: "/students",
    },
    {
        path: "/students/create-student",
        canAccess: [...SystemAdmins, UserRoles.DistrictAdmin, UserRoles.SchoolAdmin, UserRoles.GeneralClassroom, UserRoles.SchoolMentalHealth]
    },
    {
        path: "/students/:studentId/edit",
        canAccess: [...SystemAdmins, UserRoles.DistrictAdmin, UserRoles.SchoolAdmin, UserRoles.GeneralClassroom, UserRoles.SchoolMentalHealth]
    },
    {
        path: "/students/:studentId",
    },
    {
        path: "/cohorts",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/cohorts/create-cohort",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/cohorts/:cohortId/edit",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/cohorts/:cohortId",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/connected-apps",
        canAccess: [...SystemAdmins]
    },
    {
        path: "/connected-apps/create-app",
        canAccess: [UserRoles.SuperAdmin]
    },
    {
        path: "/connected-apps/:appId/edit",
        canAccess: [UserRoles.SuperAdmin]
    },
    {
        path: "/connected-apps/:appId",
        canAccess: [...SystemAdmins]
    }
];
