import React, {useEffect} from 'react';
import {get, isEmpty} from 'lodash';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useAlert} from 'react-alert';

// Components
import ModelEditComponent from '../../components/EditComponent';
import {TailSpin} from 'react-loader-spinner';

// Configs
import {getStudentsEditConfig} from '../../config/confings';

// Reducers
import {selectStudentState} from '../../store/students/reducer';
import {selectSchoolState} from '../../store/schools/reducer';
import {selectCohortState} from '../../store/cohorts/reducer';
import {selectAuthState} from '../../store/auth/reducer';

// Actions
import {getStudent, updateStudent} from '../../store/students/actions';
import {getSchools} from '../../store/schools/actions';
import {getCohorts} from '../../store/cohorts/actions';

// Constant
import {grades} from '../../constants';

// Helpers
import {getBackPage} from '../../helpers';

const StudentUpdatePage = () => {
    const dispatch = useAppDispatch();
    const {studentId} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const alert = useAlert();
    const {selectedStudent} = useAppSelector(selectStudentState);
    const {authUser} = useAppSelector(selectAuthState);
    const {schools} = useAppSelector(selectSchoolState);
    const {cohorts} = useAppSelector(selectCohortState);
    const backPage = "/students";

    useEffect(() => {
        if (studentId && selectedStudent.id !== studentId) {
            dispatch(getStudent(studentId));
        }
        if (isEmpty(schools)) {
            dispatch(getSchools());
        }
        if (isEmpty(cohorts)) {
            dispatch(getCohorts());
        }
    }, [])

    const onSave = async (data: any) => {
        try {
            const {first_name, last_name, ...rest} = data
            await dispatch(updateStudent(selectedStudent.id, rest));
            navigate(getBackPage(backPage, searchParams),{replace: true})
            alert.success("Student updated successfully");
        } catch (e: any) {
            alert.error(e.response?.data?.message);
        }
    };

    const getInitialData = () => {
        const data = {...selectedStudent};
        data.cohort = get(selectedStudent, ['cohorts', '0', 'id']);
        data.school = selectedStudent.school_id;

        return data;
    };

    return (
        <div>
            {selectedStudent.id != studentId
                ? <div className="d-flex justify-content-center align-items-center mt-5">
                    <TailSpin
                        height="80"
                        width="80"
                        color="#000"
                        visible={true}
                    />
                </div>
                :
                <ModelEditComponent
                    title="Update Student"
                    initialData={getInitialData()}
                    backPage={backPage}
                    fieldsConfig={getStudentsEditConfig(schools, cohorts, grades, get(authUser, 'role.name'))}
                    onSubmit={onSave}
                />
            }
        </div>
    )
};

export default StudentUpdatePage;
