import React, {ForwardedRef, useState} from 'react';

// Components
import {Dropdown} from 'react-bootstrap';
import ConfirmationModal from '../../Modals/ConfirmationModal';

// Styles
import './style.scss';

// Constants
import {SystemAdmins} from '../../../constants';

// Models
import {IRole} from '../../../models/IRole';

interface CustomToggleProps {
    children?: React.ReactNode,
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

interface TableOptionsInterface {
    onDelete: () => void;
    onView: () => void;
    onEdit: () => void;
    resendEmail?: () => void;
    impersonate?: () => void;
    isAdmin?: boolean;
    modalBodyText: string;
    confirmText: string;
    role?: IRole;
}

const CustomToggle = React.forwardRef(({children, onClick}: CustomToggleProps, ref: ForwardedRef<any>) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
        className="text-decoration-none"
    >
        {children}
        <span className="threeDots" />
    </a>
))
const TableOptionDropdown = ({onDelete, modalBodyText, confirmText, onView, onEdit, resendEmail, impersonate, role, isAdmin}: TableOptionsInterface) => {
    const [showModal, setShowModal] = useState(false);

    const onModalSubmit = () => {
        setShowModal(false);
        onDelete();
    }

    return (
        <>
            <Dropdown className="dropdown">
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onView}>View</Dropdown.Item>
                    <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
                    {isAdmin && <Dropdown.Item onClick={resendEmail}>Resend password email</Dropdown.Item>}
                    {/* {isAdmin && !SystemAdmins.includes(role?.name || '') &&  <Dropdown.Item onClick={impersonate}>Impersonate</Dropdown.Item>} */}
                    <Dropdown.Item onClick={() => setShowModal(true)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ConfirmationModal
                show={showModal}
                onClose={()=> setShowModal(false)}
                onSubmit={onModalSubmit}
                bodyText={modalBodyText}
                confirmText={confirmText}
            />
        </>
    )
};

export default TableOptionDropdown;
