import React, {useState} from 'react';

// Components
import {Button, Modal} from 'react-bootstrap';
import MultipleSelect from '../../Inputs/MultipleSelect';

interface AddStudentModalProps {
    show: boolean,
    onClose: () => void,
    onSubmit: (ids: number[]) => void,
    options: any,
    title: string
}

const AddStudentModal = ({show, onClose, onSubmit, options, title}: AddStudentModalProps) => {
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);

    const onSelect = (ids: number[]) => {
        setSelectedStudents(ids);
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Title>
                <h3>Add {title}</h3>
            </Modal.Title>
            <hr/>
            <Modal.Body>
                <MultipleSelect
                    label={`${title}s`}
                    onSelect={onSelect}
                    options={options}
                    placeholder="Type here..."
                />
            </Modal.Body>
            <hr/>
            <Modal.Footer>
                <Button
                    variant="outline-secondary"
                    className="rounded h-40"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="danger"
                    className="rounded h-40"
                    onClick={() => onSubmit(selectedStudents)}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default AddStudentModal;
