import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../index';

// Interfaces
import {IUser} from '../../models/IUser';
import {IStudent} from '../../models/IStudent';

export interface PaginationInterface {
    perPage: number,
    lastPage: number,
    page: number,
    total: number,
    from: number,
    to: number
}

export interface UsersFilters {
    id?: number,
    email?: string,
    first_name?: string,
    last_name?: string,
    role?: number,
    school?: number,
    district?: number,
    cohort?: number
}

interface UserState {
    users: IUser[],
    selectedUser: IUser | any,
    pagination: PaginationInterface,
    userStudentsPagination: PaginationInterface,
    filters: UsersFilters,
    sort: string,
    userStudentsSort: string,
    userStudents: IStudent[]
}

const initialState: UserState = {
    users: [],
    pagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    userStudentsPagination: {
        page: 1,
        total: 0,
        lastPage: 1,
        perPage: 10,
        from: 0,
        to: 0
    },
    selectedUser: {},
    userStudents: [],
    sort: "",
    userStudentsSort: "",
    filters: {}
};

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsersListSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                users: action.payload?.data,
                pagination: {
                    page: action.payload?.current_page,
                    perPage: action.payload?.per_page,
                    total: action.payload?.total,
                    lastPage: action.payload?.last_page,
                    from: action.payload?.from,
                    to: action.payload?.to
                }
            }
        },
        setUsersPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        },
        setUsersSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                sort: action.payload
            }
        },
        setUsersFiltersSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                filters: action.payload
            }
        },
        deleteUserSuccess: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                users: state.users.filter(item => item.id !== action.payload)
            }
        },
        updateUserSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedUser: action.payload
            }
        },
        getUserSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedUser: action.payload
            }
        },
        resetSelectedUserSuccess: (state) => {
            return {
                ...state,
                selectedUser: {}
            }
        },
        getUserStudentsSuccess: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                userStudents: action.payload.data,
                userStudentsPagination: {
                    page: action.payload.current_page,
                    perPage: action.payload.per_page,
                    total: action.payload.total,
                    lastPage: action.payload.last_page,
                    from: action.payload.from ?? 0,
                    to: action.payload.to ?? 0
                }
            }
        },
        setUserStudentsPaginationSuccess: (state, action: PayloadAction<object>) => {
            return {
                ...state,
                userStudentsPagination: {
                    ...state.userStudentsPagination,
                    ...action.payload
                }
            }
        },
        setUserStudentsSortSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                userStudentsSort: action.payload
            }
        }
    }
});

export const {getUsersListSuccess, setUsersPaginationSuccess, setUsersFiltersSuccess, setUsersSortSuccess,
    deleteUserSuccess, updateUserSuccess, getUserSuccess, getUserStudentsSuccess, setUserStudentsPaginationSuccess,
    setUserStudentsSortSuccess, resetSelectedUserSuccess} = userSlice.actions;
export const selectUserState = (state: RootState) => state.users;
export const userReducer = userSlice.reducer;
