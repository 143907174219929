import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

// Styles
import './style.scss';

interface SwitchProps {
    label: string,
    name: string,
    value?: boolean,
    onChange: (name: string, value: boolean) => void
}

const SwitchInput = ({label, name, value, onChange}: SwitchProps) => {
    const [checked, setChecked] = useState(value ?? true);

    useEffect(() => {
        if (typeof value === "undefined") {
            onChange(name, true);
        }
    },[]);

    const handleCheck = () => {
        setChecked(!checked);
        onChange(name, !checked);
    };

    return (
        <div className="d-flex flex-column" key={uuidv4()}>
            <label className="fw-bold mb-1">{label}</label>
            <div className="switcher" onClick={handleCheck}>
                <div className={`${checked && "checked"}`}>
                    Yes
                </div>
                <div className={`${!checked && "checked"}`}>
                    No
                </div>
            </div>
        </div>
    )
}

export default SwitchInput;