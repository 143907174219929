import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAppSelector} from '../../hooks/redux';
import {v4 as uuidv4} from 'uuid';
import {get, isEmpty} from 'lodash';

// Components
import CustomTextarea from '../Inputs/CustomTextarea';
import CustomCheckbox from '../Inputs/CustomCheckbox';
import CustomSelect from '../Inputs/CustomSelect';
import AvatarComponent from '../AvatarComponent';
import CustomInput from '../Inputs/CustomInput';
import SwitchInput from '../Inputs/Switch';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';

// Interfaces
import {StateInterface} from '../Filters';

// Reducers
import {selectErrorState} from '../../store/errors/reducer';

// Helpers
import {getBackPage} from '../../helpers';
import TypeaheadSelect from "../Inputs/TypeaheadSelect";

interface ModelDetailsInterface {
    title: string,
    fieldsConfig: any,
    initialData: {},
    backPage: string,
    onSubmit: (data: object) => void,
    onStateChange?: (state: StateInterface) => void
}

export interface FieldsConfigInterface {
    fieldType: string,
    name: string,
    label: string,
    type?: string,
    options?: any,
    size?: "small" | "medium" | "large" | "huge",
    displayKey?: string
    readOnly?: boolean
}

const ModelEditComponent = ({title, fieldsConfig, initialData, backPage, onSubmit, onStateChange}: ModelDetailsInterface) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {errors} = useAppSelector(selectErrorState);
    const [detailsData, setDetailsData] = useState<StateInterface>(initialData);
    const details = useRef<any>(initialData);

    useEffect(() => {
        const inputNames = fieldsConfig.filter((item: FieldsConfigInterface) => item.fieldType === "input")
            .map((item: FieldsConfigInterface) => item.name);
        
        details.current = Object.fromEntries(Object.entries(details.current).filter(([key]) => inputNames.includes(key)));

        const avatarField = fieldsConfig.filter((item: FieldsConfigInterface) => item.fieldType === "avatar");
        if (!isEmpty(avatarField)) {
            setDetailsData({...detailsData, 'avatar': detailsData['avatar'] ?? (Math.floor(Math.random() * (14 - 1 + 1)) + 1)})
        }
    }, [])

    const onFieldChange = (name: string, value: number | string | boolean) => {
        setDetailsData({...detailsData, [name]: value});
    };

    const onInputChange = (name: string, value: number | string | boolean) => {
        details.current = {...details.current, [name]: value};
    };

    useEffect(() => {
        if (onStateChange) {
            onStateChange(detailsData);
        }
    }, [detailsData])

    const onMultipleCheckboxChange = (name: string, checkboxName: string, checkboxValue: boolean) => {
        setDetailsData({
            ...detailsData,
            [name]: {
                ...detailsData[name],
                [checkboxName]: checkboxValue
            }
        });
    };

    const getField = (fieldConfig: FieldsConfigInterface) => {
        const {name, type, label, options, size, displayKey, readOnly} = fieldConfig;
        switch (fieldConfig.fieldType) {
            case "input":
                return <CustomInput
                    name={name}
                    key={uuidv4()}
                    type={type}
                    placeholder="Type here..."
                    value={details.current[name]}
                    onChange={onInputChange}
                    error={get(errors, [`${name}`, '0'])}
                    size={size ?? "huge"}
                    label={label}
                    className="mb-3"
                    readOnly={readOnly}
                />
            case "select":
                return <CustomSelect
                    name={name}
                    key={uuidv4()}
                    label={label}
                    size={size ?? "huge"}
                    placeholder="Choose one..."
                    options={options}
                    displayKey={displayKey}
                    value={detailsData[name]}
                    error={get(errors, [`${name}`, '0'])}
                    onChange={onFieldChange}
                    className="mb-3"
                />
            case "typeahead_select":
                return <TypeaheadSelect
                    name={name}
                    key={uuidv4()}
                    label={label}
                    placeholder="Choose one..."
                    options={options}
                    displayKey={displayKey}
                    value={detailsData[name]}
                    error={get(errors, [`${name}`, '0'])}
                    onChange={onFieldChange}
                    className="mb-3"
                />
            case "checkbox":
                return <CustomCheckbox
                    name={name}
                    key={uuidv4()}
                    label={label}
                    checked={detailsData[name]}
                    onChange={onFieldChange}
                    className="mb-3"
                />
            case "multiple_checkbox":
                return <div className="d-flex flex-column" key={uuidv4()}>
                    <label className="fw-bold mb-1">{label}</label>
                    <div className="d-flex w-huge">
                        {options.map((item: { id: number, name: string }) => (
                            <CustomCheckbox
                                name={`${item.id}`}
                                key={uuidv4()}
                                label={item.name}
                                checked={detailsData[name] ? detailsData[name][item.id] : false}
                                onChange={(checkboxName, checkboxValue) => onMultipleCheckboxChange(name, checkboxName, checkboxValue)}
                            />
                        ))}
                    </div>
                </div>
            case "switch":
                return <SwitchInput
                    name={name}
                    key={uuidv4()}
                    label={label}
                    value={detailsData[name]}
                    onChange={onFieldChange}/>
            case "textarea":
                return <CustomTextarea
                    name={name}
                    key={uuidv4()}
                    label={label}
                    value={detailsData[name]}
                    onChange={onFieldChange}
                    error={get(errors, [`${name}`, '0'])}
                    size={size ?? "huge"}
                    className="mb-3"
                    placeholder="Type here..."
                />
            case "avatar":
                return <AvatarComponent
                    avatar={detailsData[name]}
                    className="mb-3"
                    name={name}
                    onChange={onFieldChange}
                />
        }
    };

    const navigateBack = () => {
        navigate(getBackPage(backPage, searchParams),{replace: true})
    };

    return (
        <Container className="mb-4">
            <Row className="justify-content-center">
                <Col xs={12} md={6} lg={6}>
                    <Card>
                        <Card.Body>
                            <div>
                                <h2>{title}</h2>
                            </div>
                            <hr/>
                            <div className="d-flex flex-column w-huge mx-auto mb-4 align-items-start">
                                {fieldsConfig.map((field: FieldsConfigInterface) => getField(field))}
                            </div>
                            <hr/>
                            <div className="d-flex justify-content-between">
                                <Button
                                    variant="outline-secondary"
                                    className="rounded me-3 h-40"
                                    onClick={navigateBack}
                                >
                                    Cancel
                                </Button>
                                <Button variant="primary" className="rounded h-40" onClick={() => {
                                    onSubmit({...detailsData, ...details.current});
                                }}>
                                    Save
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ModelEditComponent;
