import React from 'react';
import { v4 as uuidv4 } from 'uuid';

// Styles
import './../CustomInput/style.scss';

// Components
import {Form} from 'react-bootstrap';

export type OptionItem = {
    [key: string]: any
};

interface CustomSelectProps {
    options: OptionItem[],
    value?: string | number,
    placeholder?: string,
    name: string,
    size?: "large" | "medium" | "small" | "huge"
    className?: string,
    onChange: (name: string, value: number | string) => void,
    label?: string,
    error?: string,
    displayKey?: string
}

const CustomSelect = ({options, placeholder, value = -1, name, size = "large", className, onChange, label, error, displayKey}: CustomSelectProps) => {
    const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
        onChange(name, event.currentTarget.value);
    };

    return (
        <div className={`d-flex flex-column ${className}`}>
            {label &&
                <label className="mb-1 fw-bold">
                    {label}
                </label>
            }
            <Form.Select
                value={value}
                name={name}
                onChange={handleChange}
                className={`h-40 rounded-3 w-${size}`}
            >
                <option value={-1}>{placeholder}...</option>
                {options.map(item => (
                    <option value={name === 'role' ? item.name : item.id} key={uuidv4()}>{displayKey ? item[displayKey] : item.name}</option>
                ))}
            </Form.Select>
            {error &&
                <p className="error">{error}</p>
            }
        </div>
    )
}

export default CustomSelect;
