import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import {App} from './App';
import {setupStore} from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import {interceptor} from './config/axios';
import {positions, Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

const store = setupStore();
interceptor(store);
const options = {
    timeout: 3000,
    position: positions.TOP_RIGHT
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
            <App/>
        </AlertProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
